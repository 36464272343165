import configReducer from "../features/config/configSlice";
import notesReducer from "../features/notes/store/notesSlice";
import layoutReducer from "../features/layout/layoutSlice";
const reducer = {
  config: configReducer,
  notes: notesReducer,
  layout: layoutReducer,
};

export default reducer;
