import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import { useParams } from "react-router-dom";
import NotePreview from "../NotePreview/NotePreview";
import { useEffect, useState } from "react";
import { getNote, getNoteContent } from "../../github/noteApi";
import AttachmentList from "../Attachments/AttachmentList";
import { getNoteAttachments } from "../../getters";
import Button from "../../../toolkit/Button";
import Div from "../../../toolkit/Div";
import { useDispatch } from "react-redux";
import {
  activateLoadingLine,
  deactivateLoadingLine,
} from "../../../layout/layoutSlice";
import { isNoteShareDomain } from "../../../../shared-note-subdomain";

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  padding: 1rem;
  justify-content: center;
  .shared-note__error {
    margin-top: 1rem;
    font-size: 1.5rem;
    padding: 1rem;
  }
  h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  ${styleSystem()}
`;

const SharedNote = ({ ...props }) => {
  const { id } = useParams();
  const [content, setContent] = useState();
  const [note, setNote] = useState();
  const [showAttachments, setShowAttachments] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add("shared-note");
  }, []);
  useEffect(() => {
    (async () => {
      if (id) {
        dispatch(activateLoadingLine());
        try {
          const note = await getNote(id, { anonymous: true });
          if (note) {
            const content = await getNoteContent(note);
            setContent(content);
            setNote(note);
          } else {
            setContent(null);
          }
        } finally {
          dispatch(deactivateLoadingLine());
        }
      }
    })();
  }, [dispatch, id]);
  if (!note) return null;
  const attachments = getNoteAttachments(note);

  // We dont want shared notes being loaded in the app domain as they will
  // have access to localstorage
  if (!isNoteShareDomain()) {
    return null;
  }
  return (
    <Wrapper {...props}>
      <Div className="container" width="100%" maxWidth="1280px">
        {content === null && (
          <h1 className="shared-note__error">
            The document you requested does not exist
          </h1>
        )}
        {attachments.length > 0 && (
          <Div display="flex" justifyContent="flex-end">
            {" "}
            <Button
              mt={10}
              variant="secondary"
              mr={10}
              onClick={() => setShowAttachments((x) => !x)}
            >
              {showAttachments ? "Show Note" : "Show Attachments"}
            </Button>
          </Div>
        )}
        {!showAttachments && (
          <NotePreview editable={false} note={note} content={content?.data} />
        )}
        {showAttachments && (
          <>
            <h1>Attachments</h1>
            <AttachmentList
              attachments={getNoteAttachments(note)}
            ></AttachmentList>
          </>
        )}
      </Div>
    </Wrapper>
  );
};

export default SharedNote;
