import styled from "styled-components";
import { styleSystem } from "../../toolkit/styleSystem";
import { UsersIcon } from "@heroicons/react/24/solid";

const Wrapper = styled.span`
  line-height: 0;
  color: ${(p) => p.theme.colors.secondary[1]} ${styleSystem()};
`;

const SharedIcon = ({ ...props }) => {
  return (
    // <Wrapper {...props}>
    <UsersIcon {...props}></UsersIcon>
    // </Wrapper>
  );
};

export default SharedIcon;
