import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import Portal from "../../../toolkit/Portal";
import Button from "../../../toolkit/Button";
import theme from "../../../../theme";
import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Wrapper = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${styleSystem()}
`;
const footerHeight = theme.sizes.footerHeight;
const FullScreenPreview = ({ onClose, filename, children, ...props }) => {
  const [fullscreen, setFullscreen] = useState(false);
  useEffect(() => {
    const root = document.getElementsByTagName("html")[0];
    root.classList.add("has-dialog");
    return () => {
      root.classList.remove("has-dialog");
    };
  });
  return (
    <Portal>
      <Wrapper
        className="fullscreen-preview"
        pb={[footerHeight, footerHeight, 0]}
        {...props}
      >
        <Header color="text" px={8}>
          <Filename fontSize={0}>{filename}</Filename>
          <div>
            <Button
              variant="secondary"
              height="unset"
              onClick={() => setFullscreen((x) => !x)}
            >
              {fullscreen ? "Fit Screen" : "Actual Size"}
            </Button>
          </div>
          <div>
            <Button variant="transparent" onClick={onClose}>
              <XMarkIcon className="h-7" />
            </Button>
          </div>
        </Header>
        <Content fullscreen={fullscreen} onClick={onClose}>
          <File
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children({ fullscreen: fullscreen })}
          </File>
        </Content>
      </Wrapper>
    </Portal>
  );
};

const Filename = styled.div`
  ${styleSystem()}
`;
// const Doc = styled.div`
// position
// `;
const File = styled.div`
  display: contents;
`;
const Content = styled.div`
  display: flex;
  overflow: auto;
  ${(p) => (p.fullscreen ? `display: block;` : "")}
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
  background-color: rgb(0, 0, 0, 0.9);
`;
const Header = styled.div`
  height: 50px;
  gap: 5px;
  background-color: #4f4f4f;
  display: flex;

  & > * {
    flex: 1;
  }
  & > *:nth-child(2) {
    & > * {
      margin-left: auto;
      margin-right: auto;
    }
  }

  & > *:nth-child(3) {
    & > * {
      margin-left: auto;
    }
  }

  justify-content: space-between;
  align-items: center;

  ${styleSystem()}
`;
export default FullScreenPreview;
