import { useEffect, useState } from "react";
import * as api from "../features/notes/github/noteApi";
import { createLogger } from "../utils/log";

const log = createLogger('useAttachment');
export default function useAttachment(attachment) {
  const [source, setSource] = useState();
  useEffect(() => {
    (async () => {
      let blob;
      if(attachment) {

        try {
          const data = await api.getAttachment(attachment);
          blob = await (await fetch(data)).blob();
        } catch (err) {
          log.error('failed to retrieve blob', err);
        }
        if (blob) {
          const url = URL.createObjectURL(blob);

          setSource(url);

          return () => {
            URL.revokeObjectURL(url);
          };
        }
      }
    })();
  }, [attachment]);
  return source;
}