import styled from "styled-components";
import { styleSystem } from "../../../../../toolkit/styleSystem";
import { useContext, useEffect, useRef, useState } from "react";
import { PreviewContext } from "../../PreviewContextProvider";
import { useDispatch } from "react-redux";
import Button from "../../../../../toolkit/Button";
import { saveNoteAsync } from "../../../../store";
import produce from "immer";
import yaml from "js-yaml";
import { useAutoSave } from "../../../../../../hooks/useAutoSave";
import { createLogger } from "../../../../../../utils/log";
import isEqual from "lodash-es/isEqual";
import { PopoverMenuItem, Popover, PopoverMenu } from "../../../../../toolkit";
import storage from "../../../../../../utils/storage";
import { useLogUnmount } from "../../../../../../hooks/useLogUnmount";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";

const SHOW_DONE_ITEMS_KEY = "TODO_SHOW_DONE";
const log = createLogger("todo");
const StyledTodo = styled.div`
  max-width: 500px;
  margin-bottom: 2rem;
  ${styleSystem()}
`;

function setState(fn) {
  return (x) => {
    return produce(x, fn);
  };
}

function useTask(children, id) {
  const dispatch = useDispatch();
  const focusIdRef = useRef();
  const { note, content, editable } = useContext(PreviewContext);
  const [data, setData] = useState({ items: [], loaded: false });
  const originalDataRef = useRef();

  // we need this to cater for scenario where application startup loads from cache
  // new value comes in via external update flagging requiring a change.
  // when note is blured, old changes overwrite new changes.222222222
  const hasChangesRef = useRef(false);
  useAutoSave(save);

  useEffect(() => {
    const newData = yaml.load(children);
    originalDataRef.current = newData;
    hasChangesRef.current = false;
    if (newData) {
      newData.items = (newData.items || []).filter((x) => !!x.desc);
      setData(newData);
    }

    // we need a loaded flag because the save on unmount could happen
    // on the same render cycle as loading state fr
    setData((x) => ({ ...x, loaded: true }));
    sortItems();
  }, [children]);

  function getById(state, id) {
    return state.items.find((x) => x.id === id);
  }

  function nextId(state) {
    let id = 1;
    state.items.forEach((x) => {
      if (x.id > id) {
        id = x.id;
      }
    });
    return id + 1;
  }

  function sortItems() {
    // we set a timeout to allow clicking on checked to lose focus
    setTimeout(() => {
      setData(
        setState((state) => {
          state.items.sort((a, b) => {
            if (a.done && !b.done) return 1;
            if (!a.done && b.done) return -1;
            if (!a.done) {
              return a.id - b.id;
            }

            if (a.doneDate && b.doneDate) {
              return a.doneDate - b.doneDate;
            }
            return a.id - b.id;
          });
        })
      );
    }, 100);
  }

  function add() {
    focusIdRef.current = nextId(data);
    setData(
      setState((x) => {
        const item = {
          id: focusIdRef.current,
          done: false,
          desc: "",
          addDate: Date.now(),
        };
        x.items.push(item);
        sortItems(x);
      })
    );
    hasChangesRef.current = true;
  }

  function onDescriptionChange(id, value) {
    setData(
      setState((x) => {
        const note = getById(x, id);
        note.desc = value;
      })
    );
    hasChangesRef.current = true;
  }

  function onCheckChange(id) {
    setData(
      setState((x) => {
        const todo = getById(x, id);
        todo.done = !todo.done;
        if (todo.done) {
          todo.doneDate = Date.now();
        }
        sortItems(x);
      })
    );
    hasChangesRef.current = true;
  }

  function onRemove(item) {
    console.log("removing", item);
    setData(
      setState((data) => {
        data.items = data.items.filter((x) => x.id !== item.id);
        sortItems(data);
      })
    );
    hasChangesRef.current = true;
  }

  function onBlur() {
    setData(
      setState((data) => {
        data.items = data.items.filter((x) => !!x.desc);
        sortItems(data);
      })
    );
    hasChangesRef.current = true;
  }

  function save() {
    if (!hasChangesRef.current) return;
    if (!data.loaded) return;

    if (isEqual(data, originalDataRef.current)) return;

    log.info("saving", { data, original: originalDataRef.current });
    originalDataRef.current = data;
    hasChangesRef.current = false;

    const escape = "```";
    const prefix = `${escape}${id}`;
    const text = `${prefix}\\n.*?${escape}`;
    const regex = new RegExp(text, "is");

    const newContent = content.replace(
      regex,
      `${prefix}\n${yaml.dump(data)}\n${escape}`
    );
    dispatch(saveNoteAsync(note, newContent));
  }

  return {
    save,
    onBlur,
    onRemove,
    onCheckChange,
    onDescriptionChange,
    editable,
    add,
    data,
    focusIdRef,
  };
}

const Task = ({ id, subType, children, ...props }) => {
  const {
    onDescriptionChange,
    onCheckChange,
    editable,
    add,
    onRemove,
    focusIdRef,
    onBlur,
    data,
  } = useTask(children, id);
  const [showMenu, setShowMenu] = useState();
  const [showDone, setShowDone] = useState(
    storage.getItem(SHOW_DONE_ITEMS_KEY)
  );
  useLogUnmount(log, "toto list");

  return (
    <StyledTodo {...props}>
      {data.items
        .filter((x) => showDone || !x.done || !editable)
        .map((item) => (
          <Row key={item.id}>
            <input
              disabled={!editable}
              type="checkbox"
              onChange={() => onCheckChange(item.id)}
              checked={item.done}
            />
            <Description
              done={item.done}
              value={item.desc}
              editable={editable}
              onBlur={onBlur}
              onEnter={add}
              onRemove={() => onRemove(item)}
              focus={focusIdRef.current === item.id}
              onChange={(val) => onDescriptionChange(item.id, val)}
            />
          </Row>
        ))}
      {editable && (
        <StyledFooter>
          <Button onClick={add}>Add</Button>
          <Popover>
            <Button variant="transparent" onClick={() => setShowMenu(true)}>
              <EllipsisHorizontalIcon className={"h-8"} />
            </Button>
            {showMenu && (
              <PopoverMenu width="10em" onClose={() => setShowMenu(false)}>
                <PopoverMenuItem>
                  {" "}
                  <Button
                    px={10}
                    width="100%"
                    height="100%"
                    onClick={() => {
                      storage.setItem(SHOW_DONE_ITEMS_KEY, !showDone);
                      setShowDone(!showDone);
                      setShowMenu(false);
                    }}
                    variant="invisible"
                  >
                    {showDone ? "Hide Done" : "Show Done"}
                  </Button>
                </PopoverMenuItem>
              </PopoverMenu>
            )}
          </Popover>
        </StyledFooter>
      )}
    </StyledTodo>
  );
};

const StyledFooter = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  align-items: center;
  position: relative;
`;

const StyledDescription = styled.div`
  padding-left: 1rem;
  flex-grow: 1;
  display: flex;
  gap: 1rem;
  align-items: center;
`;
const Description = ({
  editable,
  focus,
  done,
  onFocus,
  onEnter,
  onChange,
  onRemove,
  onBlur,
  value,
}) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();
  useEffect(() => {
    if (focus) {
      inputRef.current?.focus();
    }
  }, [focus]);

  useEffect(() => {
    function input() {
      onChange(inputRef.current.innerText);
    }
    function onKeyDown(e) {
      if (e.key === "Enter") {
        e.preventDefault();
        inputRef.current?.blur();
        onEnter && onEnter();
      }
    }

    const current = inputRef.current;
    inputRef.current.addEventListener("input", input, false);
    inputRef.current.addEventListener("keydown", onKeyDown, false);
    return () => {
      current?.removeEventListener("input", input, false);
      current?.removeEventListener("keydown", onKeyDown, false);
    };
  }, [onChange, onEnter]);

  useEffect(() => {
    if (!inputRef.current.innerText) {
      inputRef.current.innerText = value;
    }
  }, [value]);
  function _onChange(e) {
    onChange && onChange(e.target.value);
  }

  return (
    <StyledDescription onFocus={onFocus}>
      <Content
        done={done}
        contentEditable={true}
        ref={inputRef}
        innerText={value}
        variant="inset"
        onChange={_onChange}
        onBlur={() => setFocused(false) || (onBlur && onBlur())}
        onFocus={() => setFocused(true)}
        autoFocus={focus}
        inputProps={{
          disabled: !editable,
        }}
      />
      {editable && focused && (
        <Button
          onMouseDown={(e) => e.preventDefault()}
          height="1.5rem"
          onClick={onRemove}
          variant="transparent"
        >
          <i className="fas fa-times-circle"></i>
        </Button>
      )}
    </StyledDescription>
  );
};

const Content = styled.div`
  :focus-visible,
  :focus {
  }
  :focus {
    outline: none !important;
  }
  min-width: 10rem;
  ${(p) => (p.done ? `text-decoration: line-through;` : ``)}
`;
const Row = styled.div`
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  ${styleSystem()}
`;

export default Task;
