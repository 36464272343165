import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import { useSelector } from "react-redux";

import { selectSelectedNote } from "../../store";
import { getNoteAttachments } from "../../getters";
import DropZone from "./DropZone";
import AttachmentList from "./AttachmentList";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1rem 1rem 5rem;

  h1 {
    font-size: ${(p) => p.theme.fontSizes[4]};
    font-weight: ${(p) => p.theme.fontWeights[1]};
  }

  ${styleSystem()}
`;

const EditAttachments = ({ ...props }) => {
  const note = useSelector(selectSelectedNote);
  const attachments = getNoteAttachments(note);

  return (
    <Wrapper className="edit-attachments scrollbar" {...props}>
      <h1>Attachments</h1>
      <DropZone my={10} note={note}></DropZone>
      <AttachmentList isEditable attachments={attachments}></AttachmentList>
    </Wrapper>
  );
};

export default EditAttachments;
