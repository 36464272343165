import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import Button from "../../../toolkit/Button";
import Input from "../../../toolkit/Input";
import NoteList from "../NoteList/NoteList";
import Div from "../../../toolkit/Div";
import { useState } from "react";
import { search } from "../../searchDb";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSearch,
  selectIsSearching,
  selectNoteIds,
} from "../../store/notesSlice";
import { selectIsMobileView } from "../../../layout/layoutSlice";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  padding-bottom: ${(p) => p.theme.sizes.footerHeight};
  left: 0;
  width: 100%;
  margin-left: 100%;
  z-index: 1;
  background-color: ${(p) => p.theme.colors.primary[2]};
  animation: slideIn 200ms forwards;
  ${styleSystem()}
  @keyframes slideIn {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0;
    }
  }
`;

const MobileSearch = ({ ...props }) => {
  const isSearching = useSelector(selectIsSearching);
  const dispatch = useDispatch();
  const allIds = useSelector(selectNoteIds);
  const mobile = useSelector(selectIsMobileView);
  const [searchText, setSearchText] = useState("");
  const [ids, setIds] = useState(allIds);

  function onChange(evt) {
    const value = evt.target.value;
    setSearchText(value);
    if (value.length > 0) {
      setIds(search(value));
    } else {
      setIds(allIds);
    }
  }

  function onClose() {
    setSearchText("");
    dispatch(closeSearch());
  }
  if (!mobile || !isSearching) return null;
  return (
    <Wrapper className="mobile-search" {...props}>
      <TopBar>
        <Button
          onClick={onClose}
          variant="transparent"
          color="secondary.1"
          mr={5}
        >
          <ArrowLeftIcon className="h-6" />
        </Button>
        <Input
          focusOnLoad
          flexGrow={1}
          variant="inset"
          px={5}
          inputProps={{
            placeholder: "Search",
            value: searchText,
            onChange: onChange,
          }}
        />
      </TopBar>
      <Div width="100%" height="100%">
        <NoteList
          minHeight="100%"
          height="100%"
          maxHeight="100%"
          noteIds={searchText.length === 0 ? [] : ids}
        />
      </Div>
    </Wrapper>
  );
};

const TopBar = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 5px;
`;
export default MobileSearch;
