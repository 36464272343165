import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import Div from "../toolkit/Div";
import Button from "../toolkit/Button";
import Label from "../toolkit/Label";
import { tenantsChanged } from "./configSlice";

import { useDispatch } from "react-redux";
import { useFetching } from "../../hooks/useFetching";
import Alert from "../toolkit/Alert";
import * as api from "../notes/github";
import Input from "../toolkit/Input";
import { styleSystem } from "../toolkit/styleSystem";
import config from "./config";
import storage from "../../utils/storage";

const StyledWrapper = styled.div`
  ${(p) =>
    p.fullscreen
      ? css`
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10000;
        `
      : ""}
`;

const Header = styled.h2`
  ${styleSystem()}
`;
export default function CaptureAccessToken({ fullscreen }) {
  const patInput = useRef();
  const loader = useFetching();
  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();

  const updateDetails = async () => {
    await loader.action(async () => {
      const pat = patInput.current.value;
      const userInfo = await api.getGithubUserInfo(pat);

      config.setLoginDetails(userInfo, pat);
      await dispatch(tenantsChanged(storage.getAllTenants()));
      setSaved(true);
    });
  };

  return (
    <StyledWrapper fullscreen={fullscreen}>
      <Div p={5} display="flex" alignItems="center" flexDirection="column">
        <Div mb={2} height="4rem" width="90%">
          {loader.error && (
            <Alert variant="danger">
              {loader.error.message || "Failed to update access token."}
            </Alert>
          )}
          {loader.fetched && (
            <Alert variant="success">
              Updated successfully. Please refresh for your changes to take
              effect
            </Alert>
          )}
        </Div>

        <Div width="clamp(16rem,70%,35rem)">
          <Div mb={15} mt={10}>
            <Header mb={8} fontSize={4} fontWeight={2}>
              Access Token
            </Header>
            <p>
              A Github Personal Access Token(PAT) with access to gists is
              required to link GistDoc to your github gists.
            </p>
            <p>
              You can link multiple github accounts to the app and switch
              between them
            </p>
          </Div>
          <Label pb={3} htmlFor="access-key-input">
            GitHub Personal Access Token
          </Label>
          <Input
            mb={1}
            spellCheck={false}
            inputProps={{ type: "password" }}
            ref={patInput}
            width="20rem"
            id="access-key-input"
          />
          <Button
            loading={loader.fetching}
            mt={10}
            width={1}
            variant="primary"
            onClick={(x) => updateDetails(x)}
          >
            Submit
          </Button>

          {saved && (
            <Button
              loading={loader.fetching}
              mt={20}
              width={1}
              variant="secondary"
              onClick={(x) => (window.location = window.location.origin)}
            >
              Restart Now
            </Button>
          )}

          <Footer >
            <a href="https://gistdoc.tty.nz/shared/2afb2f7a664519c3f379adb7a30da3b5" target="_blank"> <Div> Terms of use</Div></a>

            <a href="https://gistdoc.tty.nz/shared/afabc92c78e408af57a40be6aa4dec9e" target="_blank"> <Div> Privacy policy</Div></a>

            <a href="https://gistdoc.tty.nz/shared/1618590da4eab9c3fcbd124c57a010fa" target="_blank"> <Div> Licence</Div></a>
          </Footer>
        </Div>
      </Div>
    </StyledWrapper>
  );
}

const Footer = styled.div `
display: flex;
  justify-content: center;
  gap:2rem;
  margin-top: 2rem;
`