import axios from "axios";
import toString from "../../../utils/toString";
import * as api from "./index";
import { createLogger } from "../../../utils/log";
import {
  createGistDescriptionString,
  getConfig,
  githubApiHostName,
  parseGist,
} from "./common";
import { getGistFiles } from "./gistApi";
import { CONTENT_FILENAME } from "../getters";



export const gistTypes = {
  note: "note",
  sharedNote: "sharedNote",
};
const log = createLogger("githubApi");

export function isNotFoundError(err) {
  return err?.response?.status === 404;
}

export async function createNote({ notebook, title }) {
  const description = createNoteDescription({
    title,
    notebook,
  });
  const data = {
    description,
    public: false,
    files: { [CONTENT_FILENAME]: { content: `# ${title}` } },
  };
  const { data: note } = await axios.post(`${githubApiHostName}/gists`, data, {
    ...getConfig(),
  });
  return parseNoteGist(parseGist(note));
}

// used to delete note
export async function updateNote(note) {
  const id = note.id;
  const encodedDescription = createNoteDescription(note);

  const gist = await api.updateGist({ id, description: encodedDescription });

  return parseNoteGist(gist);
}

export async function setNoteContent(note, content) {
  if (!content) throw new Error("Note content cannot be empty");
  // TODO: Updating a note, can undelete a note
  const githubDescription = createNoteDescription(note);

  const gist = await api.updateGist({
    id: note.id,
    description: githubDescription,
    files: { [CONTENT_FILENAME]: content },
  });

  return parseNoteGist(gist);
}

export async function getNote(id, { anonymous } = {}) {
  try {
    const { data } = await axios.get(`${githubApiHostName}/gists/${id}`, {
      ...getConfig({ noAuth: anonymous }),
    });
    return parseNoteGist(parseGist(data));
  } catch (err) {
    if (isNotFoundError(err)) {
      return null;
    }
    throw err;
  }
}

export async function getAttachment(file) {
  const response = await axios.get(file.raw_url);
  return response.data;
}
export async function getNoteContent(note) {
  const content = await getGistFiles(note, CONTENT_FILENAME);
  const data = content[CONTENT_FILENAME] || "";
  return {
    id: note.id,
    data: toString(data),
  };
}

export function parseNoteGist(gist) {
  return { ...gist, ...getNoteMeta(gist.description) };
}

export function getNoteMeta({
  tags,
  title,
  notebook,
  description,
  deleted,
  shareId,
}) {
  return {
    tags: tags || [],
    title: title || "",
    notebook,
    shareId,
    description: description || "",
    deleted: !!deleted,
    type: gistTypes.note,
  };
}

function createNoteDescription(note) {
  return createGistDescriptionString(getNoteMeta(note));
}
