import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";

export const Pdf = ({ src, ...props }) => {
  return <>{<StyledObject minHeight={['500px','500px','unset']} data={src} {...props} />}</>;
};

const StyledObject = styled.object`

  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height:100%;
  aspect-ratio: 1 / 1.4142;
  display:block;
  ${styleSystem()}
`;
//${p => p.fullscreen ? `width:unset; height:unset; max-width: unset; max-height:unset;`: ''}