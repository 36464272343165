export function createIdIndexMap() {
  const idToIndex = {};
  const indexToId = {};
  let nextIndex = 1;
  function addToIndex(id) {
    if(idToIndex[id]) {
      return idToIndex[id];
    }

    const index = nextIndex;
    idToIndex[id] = index;
    indexToId[index] = id;
    nextIndex++;
    return index
  }

  function removeById(id) {
    const index = getIndexForId(id);

    delete idToIndex[id];
    delete indexToId[index];
  }

  function removeByIndex(index) {
    const id = getIdForIndex(index);
    removeById(id);
  }
  function getIdForIndex(index) {
    return indexToId[index];
  }

  function getIndexForId(id) {
    return idToIndex[id];
  }

  return {
    addToIndex,
    getIdForIndex,
    getIndexForId,
    removeByIndex,
    removeById,
  };
}
