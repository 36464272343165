import React from "react";
import mermaid from "mermaid";
import styled from "styled-components";
mermaid.initialize({
  startOnLoad: true,
  theme: "dark",
  securityLevel: "loose",
  themeVariables: {},
});

export default class Mermaid extends React.Component {
  componentDidMount() {
    mermaid.contentLoaded();
  }
  render() {
    return (
      <Content className="mermaid">
        {this.props.children?.replaceAll("```", "")}
      </Content>
    );
  }
}

const Content = styled.div`
  & > svg {
    width: auto;
  }
  overflow-x: auto;
`;
