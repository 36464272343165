import AttachmentContainer from "./AttachmentContainer";
import { AttachmentViewer } from "./AttachmentViewer";
import { createLogger } from "../../../../utils/log";
import useAttachment from "../../../../hooks/useAttachment";

const log = createLogger("attachment");
export const Attachment = ({ isEditable, attachment }) => {
  const source = useAttachment(attachment);
  return (
    <AttachmentContainer
      src={source}
      isEditable={isEditable}
      className="attachment-container"
      attachment={attachment}
      doc={({ ...props }) => (
        <AttachmentViewer {...props} type={attachment.type} src={source} />
      )}
      title={attachment.meta.filename}
    />
  );
};
