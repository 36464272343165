import styled from "styled-components";
import { styleSystem } from "./styleSystem";
import theme from "../../theme";
const spinnerColor = theme.colors.highlight;
const StyledSpinner = styled.div`
  position: relative;
  width: 1.2em;
  height: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    font-size: inherit;
    width: 1.1em;
    height: 1.1em;
    margin: 0.15em;
    border: 0.15em solid ${spinnerColor};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${spinnerColor} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${styleSystem()}
`;

export default function Spinner({ ...props }) {
  return (
    <StyledSpinner {...props}>
      <div />
      <div />
      <div />
      <div />
    </StyledSpinner>
  );
}
