import styled from "styled-components";

export const HighlightOnHover = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255, 0);
  display:flex;
  justify-content: center;
  align-items: center;

  :hover {
  background-color: rgb(255,255,255,0.1);
  }
`;