import { useEffect } from "react";
import { createLogger } from "../utils/log";

const log = createLogger('focusChanged');
export const hasDocument = typeof document != "undefined";
export const hasWindow = typeof window != "undefined";
function noop() {}
// For node and React Native, `add/removeEventListener` doesn't exist on window.
const onWindowEvent = (hasWindow && window.addEventListener) || noop;
const onDocumentEvent = (hasDocument && document.addEventListener) || noop;
const offWindowEvent = (hasWindow && window.removeEventListener) || noop;
const offDocumentEvent = (hasDocument && document.removeEventListener) || noop;

const isVisible = () => {
  const visibilityState = hasDocument && document.visibilityState;
  log.info('visibility state', visibilityState)
  if (visibilityState !== undefined) {
    return visibilityState !== "hidden";
  }
  return true;
};

export const onFocusChange = (cb) => {
  // focus revalidate
  onDocumentEvent("visibilitychange", cb);
  onWindowEvent("focus", cb);
  return () => {
    offDocumentEvent("visibilitychange", cb);
    offDocumentEvent("focus", cb);
  };
};
export default function useFocusChanged(cb) {
  useEffect(() => {
    return onFocusChange(cb);
  }, [cb]);
}

export function useOnBlur(cb) {
  useEffect(() => {
    function notify(x) {
      if (!isVisible() || x.type === "blur") {
        cb && cb(x);
      }
    }

    onDocumentEvent("visibilitychange", notify);
    onWindowEvent("blur", notify);
    return () => {
      offDocumentEvent("visibilitychange", notify);
      offDocumentEvent("blur", notify);
    };
  }, [cb]);
}
