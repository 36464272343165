import styled from "styled-components";
import { styleSystem } from "../../toolkit/styleSystem";
import { useSelector } from "react-redux";
import { selectAllTenants } from "../../config";
import config from "../../config/config";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";
import { useRef } from "react";
import Div from "../../toolkit/Div";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 200;
  left: 10px;
  top: 55px;
  width: 20rem;
  padding: 1rem;
  border-radius: 3px;
  background-color: ${(p) => p.theme.colors.primary[1]};
  ${styleSystem()}
`;

const Row = styled.div`
  border-radius: 3px;
  border-bottom: 1px solid ${(p) => p.theme.colors.gray[5]};
  padding: 1rem 0.5rem;

  &:hover {
    background-color: ${(p) => p.theme.colors.gray[6]};
  }
  cursor: pointer;
  ${styleSystem()}
`;
const Header = styled.div`
  ${styleSystem()}
`;
const ChangeTenant = ({ close, ...props }) => {
  const wrapperRef = useRef();
  const tenants = useSelector(selectAllTenants);
  const onChangeTenant = (tenant) => {
    config.changeTenant(tenant);
  };

  useDetectOutsideClick(() => {
    close && close();
  }, wrapperRef);

  return (
    <Wrapper ref={wrapperRef} {...props}>
      <Div fontSize={0} mb={4}> V {process.env.REACT_APP_VERSION}</Div>
      <Header mb={10} fontSize={3} fontWeight={2}>
        Change Account
      </Header>
      {tenants.map((x) => (
        <Row onClick={() => onChangeTenant(x)} key={x}>
          {x}
        </Row>
      ))}
    </Wrapper>
  );
};

export default ChangeTenant;
