import { useSelector } from "react-redux";
import { selectNoteEntities } from "./store/notesSlice";
import * as searchDb from "./searchDb";
import { useEffect, useRef, useState } from "react";
import storage from "../../utils/storage";

const SEARCH_TERM_KEY = "SEARCH_TERM";
const MAX_SEARCH_TERMS = 5;
export default function useSearch() {
  const notes = useSelector(selectNoteEntities);
  const lastTerm = useRef();
  const [previousSearchTerms, setPreviousSearchTerms] = useState([]);

  useEffect(() => {
    setPreviousSearchTerms(storage.getItem(SEARCH_TERM_KEY) || []);

    return () => {
      if (lastTerm.current) {
        const terms = [
          lastTerm.current,
          ...(storage.getItem(SEARCH_TERM_KEY) || []),
        ];
        storage.setItem(
          SEARCH_TERM_KEY,
          [...new Set(terms)].slice(0, MAX_SEARCH_TERMS)
        );
      }
    };
  }, []);

  function search(term, limit) {
    const results = searchDb.search(term, limit);
    if (results.length) {
      lastTerm.current = term;
    }
    // notes could be null if they have not loaded yet for id.
    return results.map((x) => notes[x]).filter(Boolean);
  }

  return { search, previousSearchTerms };
}
