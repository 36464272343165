/**
 * @description
 * This function replaces the hashtag prefix in a body
 * of text with something else.
 *
 * The usecase is Flex search strips off the # when
 * performing searches so we need to replace it
 * with something else in order to search by #hashtag
 * ignores # prefix within strings eg abc#fda.
 * Only matches hashtags prefixed with whitespace or start of text
 *
 * The appendTagValue option is used when adding text to search index so searches for
 * todo will match results with tag #todo
 * @param text
 * @param newPrefix
 * @returns {*}
 */
import toString from "./toString";

export default function replaceHashPrefixInText(
  text,
  appendTagValue = false,
  newPrefix = "zz"
) {
  // safari doesnt support lock behind (:<= stuff)
  text = toString(text);
  return text.replace(/(^|\s)(#[a-z0-9_]+)/gims, (_, x1, x2) => {
    const hashValue = x2.substr(1);
    const appendedValue = appendTagValue ? ` ${hashValue}` : "";
    return `${x1}${newPrefix}${hashValue}${appendedValue}`;
  });
}
