import { createSelector, createSlice } from "@reduxjs/toolkit";

export const name = "layout";

export const layoutSlice = createSlice({
  name,
  initialState: {
    width: null,
    height: null,
    useMobileView: null,
    sideNavExpanded: false,
    loadingLineCount: 0,
  },
  reducers: {
    windowSizeChanged: (state, action) => {
      const { width, height, isMobileView } = action.payload;
      state.width = width;
      state.height = height;
      state.useMobileView = isMobileView;
    },
    toggleExpandSideNav: (state) => {
      state.sideNavExpanded = !state.sideNavExpanded;
    },
    activateLoadingLine: (state) => {
      state.loadingLineCount++;
    },

    deactivateLoadingLine: (state) => {
      state.loadingLineCount--;
    },
  },
  extraReducers: {},
});

const selectSelf = (state) => state[name];

export const selectIsMobileView = createSelector(
  selectSelf,
  (state) => state.useMobileView
);

export const selectSideNavExpanded = createSelector(
  selectSelf,
  (state) => state.sideNavExpanded
);

export const selectShowLoadingLine = createSelector(
  selectSelf,
  (state) => !!state.loadingLineCount
);

export const {
  windowSizeChanged,
  toggleExpandSideNav,
  activateLoadingLine,
  deactivateLoadingLine,
} = layoutSlice.actions;

export default layoutSlice.reducer;
