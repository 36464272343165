import { useCallback, useEffect, useRef } from "react";
import { useOnBlur } from "./useFocusChanged";
import { createLogger } from "../utils/log";

const log = createLogger('useAutoSave')
export function useAutoSave(saveFn) {
  const saveRef = useRef(saveFn);
  saveRef.current = saveFn;
  const save = useCallback(() => saveRef.current(), []);
  useOnBlur(save);

  useEffect(() => {
    return () => {
      log.info('saving on destroy')
        save();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}