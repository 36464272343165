import styled from "styled-components";

import Div from "../../toolkit/Div";
import { useNavigate, useLocation } from "react-router-dom";
import useRouting, { routes } from "../../../hooks/useRouting";
import { useDispatch, useSelector } from "react-redux";
import { addNoteAsync, syncNotesAsync } from "../../notes/store";
import IconButton from "./IconButton";
import CircleButton from "./CircleButton";
import { useState } from "react";
import SearchMenu from "./SearchMenu";
import { selectIsMobileView } from "../layoutSlice";
import Avatar from "./Avatar";
import ChangeTenant from "./ChangeTenant";
import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  Cog6ToothIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/solid";

const Wrapper = styled.div`
  $activeColor: #ccc;
  $inactiveColor: #737373;
  width: ${(p) => p.theme.sizes.mainSideNavSmall};
  background-color: black;
  justify-content: start;
  align-items: center;
  display: flex;
  flex-direction: column;

  padding-top: 10px;
  height: 100%;
  .popover {
  }
`;
const SideNavContent = ({ ...props }) => {
  const navigate = useNavigate();
  const { currentRoute } = useRouting();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const mobile = useSelector(selectIsMobileView);
  const [search, setSearch] = useState();
  const [changeTenant, setChangeTenant] = useState(false);

  console.log("lol search", search);
  return (
    <Wrapper>
      <CircleButton mb={8} onClick={() => setChangeTenant((s) => !s)}>
        <Avatar></Avatar>
      </CircleButton>
      {changeTenant && <ChangeTenant close={() => setChangeTenant(false)} />}
      {/*Just need this for now as we have no dedicated mobile search*/}
      {!mobile && (
        <CircleButton
          backgroundColor="gray.7"
          color="gray.4"
          onClick={() => setSearch(true)}
          mb={8}
        >
          <MagnifyingGlassIcon className="h-5" />
        </CircleButton>
      )}
      {search && <SearchMenu close={() => setSearch(false)}></SearchMenu>}
      {!mobile && (
        <CircleButton
          onClick={() => dispatch(addNoteAsync())}
          mb={13}
          backgroundColor="secondary.1"
        >
          <PlusIcon className="h-6" />
        </CircleButton>
      )}
      <IconButton
        active={pathname === routes.notes}
        onClick={() => navigate(routes.notes)}
        icon={<DocumentDuplicateIcon className="h-6" />}
      />
      <IconButton
        active={pathname === routes.deleted}
        onClick={() => navigate(routes.deleted)}
        icon={<TrashIcon className="h-6" />}
      />
      {/*<IconButton*/}
      {/*  active={currentRoute === "test"}*/}
      {/*  onClick={() => history.push(routes.test)}*/}
      {/*  icon={<BuildIcon />}*/}
      {/*/>*/}
      <Div
        display="flex"
        flexDirection="column"
        width={1}
        justifyContent="flex-end"
        flexGrow="1"
        mb={5}
      >
        <IconButton
          color="blue.5"
          onClick={() =>
            dispatch(syncNotesAsync({ showSyncing: true, fullSync: true }))
          }
          icon={<ArrowPathIcon className="h-6" />}
        />

        <IconButton
          active={currentRoute === "settings"}
          onClick={() => navigate(routes.settings)}
          icon={<Cog6ToothIcon className={"h-6"} />}
        />
      </Div>
    </Wrapper>
  );
};

export default SideNavContent;
