import styled from "styled-components";
import SideNavContent from "./SideNavContent";
import { styleSystem } from "../../toolkit/styleSystem";
import { useSelector } from "react-redux";
import { selectIsMobileView, selectSideNavExpanded } from "../layoutSlice";

const Wrapper = styled.div`
  height: 100%;
  width: ${(p) => p.theme.sizes.mainSideNavSmall}px;
  ${styleSystem()}
`;

const SideNav = ({ ...props }) => {
  const isMobile = useSelector(selectIsMobileView);
  const expanded = useSelector(selectSideNavExpanded);
  if (isMobile) {
    if (!expanded) return null;
    return (
      <Wrapper>
        {" "}
        <SideNavContent />
      </Wrapper>
    );
  }
  return (
    <Wrapper {...props}>
      <SideNavContent />
    </Wrapper>
  );
};

export default SideNav;
