import FolderTree from "../features/toolkit/FolderTree";

export default function FolderTreePage() {
  const nodes1 = [{ id: 1, label: "boo" }];
  const nodes2 = [
    { id: 1, label: "boo" },
    { id: 2, label: "Hello world", nodes: [{ id: 3, label: "whats up doc" }] },
  ];
  const nodes3 = [
    { id: 1, label: "boo" },
    { id: 2, label: "Hello world" },
    { id: 3, label: "whats up doc" },
  ];
  return (
    <div className="center" style={{ gap: "1rem", flexWrap: "wrap" }}>
      <div
        style={{
          width: "fit-content",
          padding: "2rem",
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        <FolderTree nodes={nodes1} />

        <FolderTree nodes={nodes2} />
        <FolderTree nodes={nodes3} />
      </div>
    </div>
  );
}
