import styled, { css } from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import Button from "../../../toolkit/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  softDeleteNoteAsync,
  selectSelectedNote,
  noteSelectionCleared,
  selectIsEditMode,
  setEditingMode,
  selectSelectedContent,
  toggleFullScreenNoteView,
} from "../../store/notesSlice";
import Div from "../../../toolkit/Div";
import NoteEditor from "../NoteEditor/NoteEditor";
import { selectIsMobileView } from "../../../layout/layoutSlice";
import { useEffect, useRef, useState } from "react";
import NotePreview from "./NotePreview";

import Modal from "../../../toolkit/Modal";
import ShareDialog from "./ShareDialog";

import SharedIcon from "../SharedIcon";
import EditAttachments from "../Attachments/EditAttachments";
import Scrollable from "../../../toolkit/Scrollable";
import { hasAttachments, isShared } from "../../getters";
import { Popover, PopoverMenu, PopoverMenuItem } from "../../../toolkit";
import {
  ArrowsPointingOutIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import {
  Bars3BottomLeftIcon,
  CheckIcon,
  ChevronLeftIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";

const headerHeight = "44px";
const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.primary[3]};
  //Need this to stop container from overflowing the viewpoint width
  width: 0;
  ${(p) =>
    p.mobile
      ? css`
          position: fixed;
          z-index: 20;
          width: 100%;
          top: 0;
          height: unset;
          bottom: ${(p) => (p.editMode ? "0" : p.theme.sizes.footerHeight)};

          left: 0;
          right: 0;
        `
      : ""}
  ${styleSystem()}
`;

const HeaderBar = styled.div`
  height: ${headerHeight};
  display: flex;
  align-items: stretch;
  padding: 8px 12px 4px;
  justify-content: space-between;

  ${styleSystem()};
`;

const Body = styled.div`
  height: calc(100% - ${headerHeight});
  ${styleSystem()};
`;
const NoteSection = ({ ...props }) => {
  const note = useSelector(selectSelectedNote);
  const { data } = useSelector(selectSelectedContent) || {};
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const mobile = useSelector(selectIsMobileView);
  const morePopoverRef = useRef();
  const showMoreButtonRef = useRef();
  const controllerRef = useRef();
  const isEditMode = useSelector(selectIsEditMode);
  const [showShare, setShowShare] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);

  useEffect(() => {
    if (isEditMode) {
      setShowAttachments(false);
    }
  }, [isEditMode]);
  useEffect(() => {
    setShowAttachments(false);
  }, [note?.id]);

  function deleteNote() {
    note && dispatch(softDeleteNoteAsync(note, !mobile));
    setShowMore(false);
  }

  if (!note) {
    return null;
  }

  return (
    <>
      {showShare && (
        <Modal onBackgroundClick={() => setShowShare(false)}>
          <ShareDialog />
        </Modal>
      )}
      <Wrapper
        mobile={mobile}
        editMode={isEditMode}
        className="note-section"
        {...props}
      >
        <HeaderBar>
          {mobile && !isEditMode && (
            <Button
              variant="transparent"
              color="secondary.1"
              ml={5}
              onClick={() => dispatch(noteSelectionCleared())}
            >
              <ChevronLeftIcon className={"h-6 relative"} />
            </Button>
          )}

          {mobile && isEditMode && (
            <Button
              variant="transparent"
              color="secondary.1"
              ml={5}
              onClick={() => dispatch(setEditingMode(false))}
            >
              <CheckIcon className="relative h-6" />
            </Button>
          )}

          {!mobile && !isEditMode && (
            <Button
              variant="transparent"
              color="secondary.1"
              ml={5}
              onClick={() => dispatch(setEditingMode(!isEditMode))}
            >
              <PencilIcon className="h-6 relative" />
            </Button>
          )}
          {!mobile && isEditMode && (
            <Button
              variant="transparent"
              color="secondary.1"
              ml={5}
              onClick={() => dispatch(setEditingMode(!isEditMode))}
            >
              <CheckIcon className="h-6 relative" />
            </Button>
          )}

          <Div
            display="flex"
            alignItems="center"
            gap="10px"
            justifyContent="flex-end"
            flexGrow={1}
          >
            {isShared(note) && <SharedIcon className={"h-5 text-green-500"} />}
            <Button onClick={() => setShowShare(true)}>Share</Button>
            <Divider />
            <Button
              variant="transparent"
              className={"h-full"}
              color={
                hasAttachments(note) && !showAttachments
                  ? "secondary.1"
                  : "text"
              }
              onClick={() => setShowAttachments((att) => !att)}
            >
              {!showAttachments && <PaperClipIcon className="h-6" />}
              {showAttachments && <Bars3BottomLeftIcon className={"h-6"} />}
            </Button>
            <Button
              variant="transparent"
              className={"h-full"}
              onClick={() => dispatch(toggleFullScreenNoteView())}
            >
              <ArrowsPointingOutIcon className={"h-6"} />
            </Button>
            <Popover className={"h-full"}>
              <Button
                ref={showMoreButtonRef}
                variant="transparent"
                className="h-full"
                onClick={() => setShowMore((state) => !state)}
              >
                <EllipsisHorizontalIcon className={"h-6"} />
              </Button>
              {showMore && (
                <PopoverMenu
                  onClose={() => setShowMore(false)}
                  top={16}
                  right={0}
                >
                  <PopoverMenuItem onClick={deleteNote}>
                    <Button px={10} variant="invisible">
                      Delete
                    </Button>
                  </PopoverMenuItem>
                </PopoverMenu>
              )}
            </Popover>
          </Div>
        </HeaderBar>
        <Body width="100%">
          {showAttachments ? (
            <EditAttachments />
          ) : isEditMode ? (
            <NoteEditor getController={(x) => (controllerRef.current = x)} />
          ) : (
            <Scrollable>
              <NotePreview editable={true} note={note} content={data} />
            </Scrollable>
          )}
        </Body>
      </Wrapper>
    </>
  );
};

const Divider = styled.div`
  border-right: 1px solid ${(p) => p.theme.colors.gray[5]};
  position: relative;
  right: -6px;
  height: 100%;
  //:after {
  //  display: block;
  //  content:'a';
  //}
`;

export default NoteSection;
