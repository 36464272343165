import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchFilter,
  selectIsNoteSearch,
  selectNoteIdsToDisplay,
} from "../../store/notesSlice";
import Button from "../../../toolkit/Button";
import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";
const Wrapper = styled.div`
  height: 96px;
  padding: 1rem 1.5rem;
  position: relative;
  background-color: ${(p) => p.theme.colors.primary[2]};
  border-bottom: ${(p) => p.theme.colors.highlight} 3px solid;
  s h1 {
    font-size: ${(p) => p.theme.fontSizes[3]};
    margin-bottom: 20px;
  }
  .note-list-header {
    &__summary {
      font-size: ${(p) => p.theme.fontSizes[1]};
    }

    &__title {
      display: flex;
      gap: 5px;
      margin-bottom: 1.2rem;
    }
    &__clearSearch {
      font-size: ${(p) => p.theme.fontSizes[0]};
      color: ${(p) => p.theme.colors.blue[4]};
      font-weight: ${(p) => p.theme.fontWeights[0]};
      right: 0;
    }
  }
  ${styleSystem()};
`;

const NotesListHeader = ({ ...props }) => {
  const noteIds = useSelector(selectNoteIdsToDisplay);
  const filtered = useSelector(selectIsNoteSearch);
  const dispatch = useDispatch();

  return (
    <Wrapper {...props}>
      <Button
        position="absolute"
        width="7rem"
        height="1rem"
        right="0"
        bottom="1rem"
        variant="invisible"
        onClick={() => dispatch(clearSearchFilter())}
      >
        {filtered && (
          <span className="note-list-header__clearSearch">Clear Search</span>
        )}
      </Button>
      <div className="note-list-header__title">
        <DocumentDuplicateIcon className={"h-6"} />
        {filtered ? <h1>Search results </h1> : <h1>Notes</h1>}
      </div>
      <div className="note-list-header__summary">
        <div>
          {noteIds.length} notes {filtered && "found"}
        </div>
      </div>
    </Wrapper>
  );
};
NotesListHeader.displayName = "NotesListHeader";
export default NotesListHeader;
