
const minute = 60;
const hour = minute*60;
const day = hour *24;
const week = day*7;

export default function timeSince(time) {
  if (!(time instanceof Date)) {
    time = new Date(time);
  }
  const now = Date.now() /1000;
  const past = time.getTime() /1000;
  const duration = now - past;
  if(duration < minute) {
    return 'A moment ago';
  } else if(duration < hour ) {
    return `${Math.floor(duration/60)} minutes ago`
  } else if(duration < day) {
    return `${Math.floor(duration/60/60)} hours ago`
  } else if(duration < (week)) {
    return `${Math.floor(duration/60/60/24)} days ago`
  }
  return time.toDateString()
}
