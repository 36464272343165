import styled from "styled-components";

import { styleSystem } from "./styleSystem";

const Label = styled.label`
  display: block;
  font-size: ${(props) => props.theme.fontSizes[1]};
  ${styleSystem()};
`;

export default Label;
