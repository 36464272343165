import * as api from "../../github";
import * as db from "../../db";
import { noteUpdated, selectNoteEntities } from "../notesSlice";
import { parseNoteGist } from "../../github";
import {
  activateLoadingLine,
  deactivateLoadingLine,
} from "../../../layout/layoutSlice";
import { encodeFilename } from "../../getters";

export function renameAttachmentAsync(attachment, newFilename) {
  return async (dispatch, getState) => {
    try {
      newFilename = encodeFilename({
        filename: newFilename,
        timestamp: attachment.meta.timestamp,
        type: attachment.meta.type,
      });
      dispatch(activateLoadingLine());

      const note = selectNoteEntities(getState())[attachment.noteId];

      const content = await api.getAttachment(note.files[attachment.filename]);
      const payload = {
        [attachment.filename]: { filename: newFilename, content },
      };
      if (note.shareId) {
        await api.updateFiles(note.shareId, payload);
      }

      const gist = await api.updateFiles(note.id, payload);

      const updatedNote = parseNoteGist(gist);
      await db.setNotes(updatedNote);
      dispatch(noteUpdated(updatedNote));
    } finally {
      dispatch(deactivateLoadingLine());
    }
  };
}
