import styled from "styled-components";
import { styleSystem } from "./styleSystem";
import Portal from "./Portal";
import Overlay from "./Overlay";

const Wrapper = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${styleSystem()}
`;

const Modal = ({ onBackgroundClick, children, ...props }) => {
  return (
    <Portal>
      <Wrapper className="modal" {...props}>
        <Overlay className="modal-overlay" onClick={onBackgroundClick} />
        <div className="modal-content" style={{ zIndex: 110 }}>
          {children}
        </div>
      </Wrapper>
    </Portal>
  );
};

export default Modal;
