import styled from "styled-components";
import { styleSystem } from "../../../../toolkit/styleSystem";
import { getNoteAttachment } from "../../../getters";
import useAttachment from "../../../../../hooks/useAttachment";
import { useContext, useState } from "react";
import { PreviewContext } from "../PreviewContextProvider";
import { Img } from "../../Attachments/Img";
import FullScreenPreview from "../../Attachments/FullScreenPreview";

const StyledImage = styled.div`
  ${styleSystem()}
`;

const Image = ({ children: filename, ...props }) => {
  const context = useContext(PreviewContext);
  const note = context.note;
  const attachment = getNoteAttachment(note, filename?.trim());
  const url = useAttachment(attachment);
  const [fullscreen, setFullscreen] = useState(false);
  return (
    <StyledImage {...props}>
      {fullscreen ? (
        <FullScreenPreview
          onClose={() => setFullscreen(false)}
          filename={attachment.filename}
        >
          {({ fullscreen }) => <Img src={url} fullscreen={fullscreen} />}
        </FullScreenPreview>
      ) : (
        <Img src={url} onClick={() => setFullscreen(true)} />
      )}
    </StyledImage>
  );
};

export default Image;
