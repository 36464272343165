import { useRef, useState } from "react";

export function useFetching() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState();
  const [value, setValue] = useState();
  const [fetched, setFetched] = useState(false);
  const fetchingRef = useRef(false);

  async function action(fn) {
    try {
      if (fetchingRef.current) return;

      setError(null);
      setFetched(false);
      fetchingRef.current = true;
      setValue(null);
      setFetching(true);

      const value = await fn();
      setValue(value);
      setFetched(true);
    } catch (err) {
      console.error("fetch error", err);
      setError(err);
    } finally {
      fetchingRef.current = false;
      setFetching(false);
    }
  }
  return { fetching, fetched, value, error, action };
}
