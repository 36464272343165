import styled from 'styled-components'
import { styleSystem } from "../../../../toolkit/styleSystem";
import  {Pdf as PdfAttachment}  from '../../Attachments/Pdf'
import { useContext } from "react";
import { PreviewContext } from "../PreviewContextProvider";
import { getNoteAttachment } from "../../../getters";
import useAttachment from "../../../../../hooks/useAttachment";
const StyledPdf = styled.div`
  margin:2rem 0;
  ${styleSystem()}
`;

const Pdf = ({children: filename, ...props}) => {
  const context = useContext(PreviewContext);
  const note = context.note;
  const attachment = getNoteAttachment(note, filename?.trim());
  const url = useAttachment(attachment);
  return <StyledPdf {...props}><PdfAttachment src={url} ></PdfAttachment></StyledPdf>
}

export default Pdf