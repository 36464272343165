import Mermaid from "./Mermaid";
import CodeHighlight from "./CodeHighlight";
import Task from "./Task/Task";
import { createLogger } from "../../../../../utils/log";
import { useLogUnmount } from "../../../../../hooks/useLogUnmount";
import Excalidraw from "./Excalidraw";
const log = createLogger("codeBlock");
const CodeBlock = ({ children, className }) => {
  const language = className?.replace(/language-/, "") || "typescript";

  const parts = language.split(":");
  const type = parts[0];
  const subType = parts[1] || "";

  useLogUnmount(log, "codeblock");
  if (type === "mermaid") {
    return <Mermaid>{children}</Mermaid>;
  }
  if (type === "excalidraw") {
    return <Excalidraw>{children}</Excalidraw>;
  }
  if (type === "task") {
    return (
      <Task id={language} subType={subType}>
        {children}
      </Task>
    );
  }

  return <CodeHighlight children={children} language={language} />;
};

export default CodeBlock;
