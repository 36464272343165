/* eslint-disable import/first */
import { shareDomainGuard } from "./shared-note-subdomain";

window.__DEV__ = process.env.NODE_ENV === "development";
import "./setupAxios";
import React from "react";
import { createRoot } from "react-dom/client";
import "./reset.css";
import "./index.css";
import "./easymde.css";
import "./md.css";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import store from "./App/store";
import { BrowserRouter } from "react-router-dom";
import "react-virtualized/styles.css";
import WindowResizeTracker from "./features/layout/WindowResizeTracker";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import config from "./features/config/config";
import { getGithubUserInfo } from "./features/notes/github";
import { logInfo } from "./utils/log";

// i think sentry logs web response bodies. disable for prod
// Sentry.init({
//   dsn: "https://2ce57306c9f64bf495aa37aa8102d5d3@o551318.ingest.sentry.io/5967058",
//   integrations: [new Integrations.BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// ES6

(async () => {
  if (process.env.REACT_APP_DEMO === "true") {
    logInfo("Running in demo mode");
    const demoPAT = "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";
    if (!config.getTenant() || config.getPat() !== demoPAT) {
      const userInfo = await getGithubUserInfo(demoPAT);
      config.setLoginDetails(userInfo, demoPAT);
      window.location.reload();
    }
  }
})();

(async () => {
  try {
    // eslint-disable-next-line no-restricted-globals
    if (screen?.orientation?.lock) await screen.orientation.lock("portrait");
    // eslint-disable-next-line no-restricted-globals
    if (screen?.lockOrientation) await screen.lockOrientation("portrait");
  } catch (err) {}
})();

window.document.body.addEventListener(
  "touchmove",
  (e) => {
    e.preventDefault();
  },
  false
);

shareDomainGuard();
const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <WindowResizeTracker />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
