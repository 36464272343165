import styled from "styled-components";
import NoteEditorWindow from "./NoteEditorWindow";
import Div from "../../../toolkit/Div";
import Button from "../../../toolkit/Button";
import { useRef, useState } from "react";
import { Popover, PopoverMenu, PopoverMenuItem } from "../../../toolkit";
import { styleSystem } from "../../../toolkit/styleSystem";

const toolbarHeight = 51;

const Toolbar = styled.div`
  height: 40px;
  width: 100%;
  padding: 7px 0 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledMenuItem = styled.div`
  width: 10rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  ${styleSystem()}
`;

const MenuItem = ({ ...props }) => {
  return <StyledMenuItem {...props}></StyledMenuItem>;
};

const NoteEditor = ({ getController }) => {
  const controllerRef = useRef();
  const [showMore, setShowMore] = useState(false);

  function _setController(controller) {
    controllerRef.current = controller;
    getController && getController(controller);
  }

  return (
    <Div width="100%" height="100%" display="flex" flexDirection="column">
      <Toolbar height={toolbarHeight}>
        <Popover height="100%" ml={10}>
          <Button
            height="100%"
            variant="transparent"
            onClick={() => setShowMore(true)}
          >
            <i className="fas fa-plus-circle"></i>
          </Button>
          {showMore && (
            <PopoverMenu width="16rem;" onClose={() => setShowMore(false)}>
              <PopoverMenuItem>
                <Button
                  justifyContent="flex-start"
                  pl="2rem"
                  height="100%"
                  variant="invisible"
                  onClick={() =>
                    controllerRef.current?.drawTable() || setShowMore(false)
                  }
                >
                  <i className="fas fa-table"></i>
                  <span style={{ marginLeft: "1rem" }}>Table</span>
                </Button>
              </PopoverMenuItem>
              <PopoverMenuItem>
                <Button
                  justifyContent="flex-start"
                  pl="2rem"
                  height="100%"
                  variant="invisible"
                  onClick={() =>
                    controllerRef.current?.toggleCodeBlock() ||
                    setShowMore(false)
                  }
                >
                  <i className="fas fa-code"></i>
                  <span style={{ marginLeft: "1rem" }}>Code Block</span>
                </Button>
              </PopoverMenuItem>

              <PopoverMenuItem>
                <Button
                  justifyContent="flex-start"
                  pl="2rem"
                  height="100%"
                  variant="invisible"
                  onClick={() =>
                    controllerRef.current?.mermaidDiagram() ||
                    setShowMore(false)
                  }
                >
                  <i className="fas fa-project-diagram"></i>
                  <span style={{ marginLeft: "1rem" }}>Mermaid Diagram</span>
                </Button>
              </PopoverMenuItem>

              <PopoverMenuItem>
                <Button
                  justifyContent="flex-start"
                  pl="2rem"
                  height="100%"
                  variant="invisible"
                  onClick={() =>
                    controllerRef.current?.graphviz() || setShowMore(false)
                  }
                >
                  <i className="fas fa-project-diagram"></i>
                  <span style={{ marginLeft: "1rem" }}>Graphviz Diagram</span>
                </Button>
              </PopoverMenuItem>

              <PopoverMenuItem>
                <Button
                  justifyContent="flex-start"
                  pl="2rem"
                  height="100%"
                  variant="invisible"
                  onClick={() =>
                    controllerRef.current?.json() || setShowMore(false)
                  }
                >
                  <i className="fas fa-code"></i>
                  <span style={{ marginLeft: "1rem" }}>JSON</span>
                </Button>
              </PopoverMenuItem>
              <PopoverMenuItem>
                <Button
                  justifyContent="flex-start"
                  pl="2rem"
                  height="100%"
                  variant="invisible"
                  onClick={() =>
                    controllerRef.current?.task() || setShowMore(false)
                  }
                >
                  <i className="fas fa-list"></i>
                  <span style={{ marginLeft: "1rem" }}>Task List</span>
                </Button>
              </PopoverMenuItem>
              <PopoverMenuItem>
                <Button
                  justifyContent="flex-start"
                  pl="2rem"
                  height="100%"
                  variant="invisible"
                  onClick={() =>
                    controllerRef.current?.image() || setShowMore(false)
                  }
                >
                  <i className="far fa-image"></i>
                  <span style={{ marginLeft: "1rem" }}>Image</span>
                </Button>
              </PopoverMenuItem>
              <PopoverMenuItem>
                <Button
                  justifyContent="flex-start"
                  pl="2rem"
                  height="100%"
                  variant="invisible"
                  onClick={() =>
                    controllerRef.current?.pdf() || setShowMore(false)
                  }
                >
                  <i className="far fa-file-pdf"></i>
                  <span style={{ marginLeft: "1rem" }}>Pdf</span>
                </Button>
              </PopoverMenuItem>
              <PopoverMenuItem>
                <Button
                  justifyContent="flex-start"
                  pl="2rem"
                  height="100%"
                  variant="invisible"
                  onClick={() =>
                    controllerRef.current?.video() || setShowMore(false)
                  }
                >
                  <i className="fas fa-video"></i>
                  <span style={{ marginLeft: "1rem" }}>Video</span>
                </Button>
              </PopoverMenuItem>
            </PopoverMenu>
          )}
        </Popover>
        <Divider />
        <Button
          height="100%"
          variant="transparent"
          onClick={() => controllerRef.current?.toggleHeadingSmaller()}
        >
          <i className="fas fa-heading" />
        </Button>
        <Button
          height="100%"
          variant="transparent"
          onClick={() => controllerRef.current?.toggleBold()}
        >
          <i className="fas fa-bold" />
        </Button>

        <Button
          height="100%"
          variant="transparent"
          onClick={() => controllerRef.current?.toggleItalic()}
        >
          <i className="fas fa-italic"></i>
        </Button>
        <Button
          height="100%"
          variant="transparent"
          onClick={() => controllerRef.current?.toggleUnderline()}
        >
          <i className="fas fa-underline"></i>
        </Button>
        <Button
          height="100%"
          variant="transparent"
          onClick={() => controllerRef.current?.drawHorizontalRule()}
        >
          <i className="fas fa-minus"></i>
        </Button>
        <Button
          height="100%"
          variant="transparent"
          onClick={() => controllerRef.current?.toggleStrikethrough()}
        >
          <i className="fas fa-strikethrough"></i>
        </Button>

        <Divider />
        <Button
          height="100%"
          variant="transparent"
          onClick={() => controllerRef.current?.undo()}
        >
          <i className="fas fa-undo"></i>
        </Button>

        <Button
          height="100%"
          variant="transparent"
          onClick={() => controllerRef.current?.redo()}
        >
          <i className="fas fa-redo"></i>
        </Button>
      </Toolbar>
      <Div width={1} height="0px" flexGrow="1">
        <NoteEditorWindow getController={_setController} />
      </Div>
    </Div>
  );
};

const Divider = styled.div`
  height: 70%;
  width: 0px;
  border-left: 1px solid ${(p) => p.theme.colors.gray[5]};
  margin: 0 0.4rem;
`;
export default NoteEditor;
