import React from "react";

// Handles errors rendering content due to bad mdx content being saved.
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: error };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.content !== prevProps.content) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.props.onError && this.props.onError(error, errorInfo);
  }

  render() {
    if (this.state.hasError || false) {
      // You can render any custom fallback UI
      return (
        <>
          <div style={{ color: "#ff5353", fontSize: "2rem" }}>
            Something went wrong.
          </div>
          <pre>{this.state.hasError?.message}</pre>
        </>
      );
    }

    return this.props.children;
  }
}
