import config from "../../config/config";
import { gistTypes } from "./noteApi";
import { createLogger } from "../../../utils/log";

export const NOTES_PREFIX = "@@";
export const githubApiHostName = "https://api.github.com";
const version = "application/vnd.github.v3+json";

const log = createLogger("gistApi");
export function getConfig({ pat, params, noAuth } = {}) {
  const auth = noAuth
    ? {}
    : { authorization: `bearer ${pat || config.getPat()}` };
  const headers = {
    ...auth,
    accept: version,
  };
  return {
    headers,
    params: { ...params },
  };
}

export function createGistDescriptionString(args) {
  return `${NOTES_PREFIX} ${JSON.stringify({
    ...args,
    // Initially gists were not created with a type. now they are.
    type: args.type || gistTypes.note,
  })}`;
}

export function parseGistDescription(str) {
  try {
    const data = JSON.parse(str.substr(3));
    // default is note before type added
    data.type = data.type || gistTypes.note;
    return data;
  } catch (err) {
    log.error("err while parsing gist description", { str, err });
  }
  return null;
}

export function parseGist({
  files,
  description,
  id,
  owner: { id: ownerId },
  updated_at,
  truncated,
  created_at,
}) {
  Object.entries(files).forEach(([_, value]) => {
    delete value.content;
  });
  const parsedDescription = parseGistDescription(description);
  return {
    files,
    id,
    // default is note because notes didnt have a type
    ownerId,
    updated_at: new Date(updated_at).getTime(),
    truncated,
    created_at: new Date(created_at).getTime(),
    description: parsedDescription,
    type: parsedDescription.type
  };
}
