import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import { useEffect, useRef } from "react";
import { AddAttachmentsAsync } from "../../store";
import { useDispatch } from "react-redux";
import { getNextAttachmentFilename } from "../../getters";
import Button from "../../../toolkit/Button";
import { createLogger } from "../../../../utils/log";

const log = createLogger("dropzone");

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result); // data url!
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

const Wrapper = styled.div`
  position: relative;
  border: 1px solid ${(p) => p.theme.colors.primary[0]};
  text-align: center;

  margin: auto auto;
  height: 70px;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;

  .drop_zone {
    &__editor {
    }
  }

  ${styleSystem()}
`;
const MAX_SIZE = 1024 * 1024 * 6.5; // 6.5MB;
const DropZone = ({ note, ...props }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    async function paste(event) {
      event.preventDefault();
      const items = event.clipboardData || event.originalEvent.clipboardData;
      const files = {};
      for (const index in items) {
        const item = items[index];

        if (item instanceof FileList) {
          for (let i = 0; i < item.length; i++) {
            const file = item.item(i);
            // TODO: Use error snackbar
            if (file.size && file.size > MAX_SIZE) continue;
            const content = await readFile(file);
            let { name } = file;

            name = getNextAttachmentFilename(note, name);
            files[name] = { content: content };
          }
        }
      }
      await dispatch(AddAttachmentsAsync(note, files));
    }

    window.addEventListener("paste", paste, false);
    return () => {
      window.removeEventListener("paste", paste, false);
    };
  }, [dispatch, note]);

  return (
    <Wrapper {...props}>
      <Centered>
        <div className="drop-zone__label">PASTE HERE</div>
        <Editor spellCheck={false} contentEditable={true} />
      </Centered>
      <Divider></Divider>
      <Centered className="drop-zone__label">
        <FileUpload note={note} />
      </Centered>
    </Wrapper>
  );
};

const FileUpload = ({ note }) => {
  const uploadRef = useRef();
  const dispatch = useDispatch();
  async function uploadFile(e) {
    const files = e.target.files;
    const filesToSave = {};
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      // TODO: Use error snackbar
      if (file.size && file.size > MAX_SIZE) continue;
      const content = await readFile(file);
      let { name } = file;

      name = getNextAttachmentFilename(note, name);

      filesToSave[name] = { content: content };
    }
    log.info("files to save", filesToSave);
    await dispatch(AddAttachmentsAsync(note, filesToSave));
  }
  return (
    <>
      {" "}
      <Button
        width="100%"
        onClick={() => uploadRef.current.click()}
        height="100%"
        variant="transparent"
      >
        UPLOAD
      </Button>
      <input type="file" onChange={uploadFile} ref={uploadRef} hidden />
    </>
  );
};
const Divider = styled.div`
  border-right: 1px solid ${(p) => p.theme.colors.primary[0]};
`;
const Centered = styled.div`
  display: grid;
  place-items: center;
`;

const Editor = styled.div`
  caret-color: transparent;
  color: transparent;
  overflow: hidden;
  border: none;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: auto;
  height: auto;

  :focus-visible,
  focus {
    outline: none !important;
    border: none !important;
  }

  :focus {
    outline: none !important;
    border: none !important;
  }
`;
export default DropZone;
