import styled from "styled-components";
import { styleSystem } from "../../../../toolkit/styleSystem";
import { isSafari } from "../../../../../utils/browser";
import { useContext } from "react";
import { PreviewContext } from "../PreviewContextProvider";
import { getNoteAttachment } from "../../../getters";
import useAttachment from "../../../../../hooks/useAttachment";

const StyledVideo = styled.div`
  ${styleSystem()}
`;

const Video = ({ children: filename,autoPlay, loop,  ...props }) => {
  const context = useContext(PreviewContext);
  const note = context.note;
  const attachment = getNoteAttachment(note, filename.trim());
  const url = useAttachment(attachment);
  return (
    <StyledVideo {...props}>
      <>
        {isSafari && autoPlay && loop ? (
          <img src={url} />
        ) : (
          <video style={{ maxWidth: "100%" }} src={url} autoPlay={autoPlay} loop={loop} controls muted>
            Unsupported
          </video>
        )}
      </>
    </StyledVideo>
  );
};

export default Video;
