export const CONTENT_FILENAME = "content.md";
const FILE_DELIMITER = "__";
export function getNoteAttachments(note) {
  return Object.keys(note.files)
    .map((filename) => ({
      ...note.files[filename],
      noteId: note.id,
    }))
    .filter((x) => x.filename !== CONTENT_FILENAME)
    .map((x) => ({ ...x, meta: decodeFilename(x.filename) }));
}
export function getNoteAttachment(note, filename) {
  const attachments = getNoteAttachments(note);
  const target = attachments.find(x => x.meta.filename === filename);

  return note.files[target?.filename];
}
export function hasAttachments(note) {
  return Object.keys(note.files).length > 1;
}

function splitFilename(filename) {
  const name = filename.replace(/\.[^/.]+$/, "");

  const extension = filename.substring(name.length);
  return { name, extension };
}

export function isShared(note) {
  return !!note.shareId;
}

export function getDescription(note) {
  return note.description;
}

export const fileTypes = {
  attachment: "att",
};
export function getFileType(filename) {
  if (filename.startsWith(`${fileTypes.attachment}${FILE_DELIMITER}`)) {
    return fileTypes.attachment;
  }
  // default
  return fileTypes.attachment;
}

export function encodeFileType(filename, type) {
  if (!type) throw new Error("file type required");
  return `${type}${FILE_DELIMITER}${filename}`;
}

function decodeFileType(filename) {
  const type = getFileType(filename);
  const regex = new RegExp(`^${type}${FILE_DELIMITER}`);
  filename = filename.replace(regex, "");
  return { filename, type };
}
function decodeTimestamp(filename) {
  const { name, extension } = splitFilename(filename);
  const regex = new RegExp(`${FILE_DELIMITER}[0-9]+$`);
  const result = regex.exec(name);
  const timestamp = result
    ? Number(result[0].substr(FILE_DELIMITER.length))
    : 0;

  return { filename: `${name.replace(regex, "")}${extension}`, timestamp };
}

export function decodeFilename(filename) {
  const timestampParts = decodeTimestamp(filename);
  const typeParts = decodeFileType(timestampParts.filename);
  return {
    filename: typeParts.filename,
    type: typeParts.type,
    timestamp: timestampParts.timestamp,
  };
}

export function setFileTimestamp(filename, timestamp) {
  const decoded = decodeFilename(filename);
  decoded.timestamp = timestamp || Date.now();
  return encodeFilename(decoded);
}

export function getNextAttachmentFilename(note, filename) {
  const attachments = getNoteAttachments(note);
  function exists(f) {
    return attachments.some((x) => x.meta.filename === f);
  }

  if (!exists(filename)) return filename;
  const { name, extension } = splitFilename(filename);
  for (let i = 1; ; i++) {
    const newFilename = `${name}_${i}${extension}`;
    if (!exists(newFilename)) {
      return newFilename;
    }
  }
}

function encodeTimestamp(filename, suffix) {
  const { name, extension } = splitFilename(filename);
  return `${name}${FILE_DELIMITER}${suffix}${extension}`;
}

export function encodeFilename({ filename, timestamp, type }) {
  return encodeFileType(encodeTimestamp(filename, timestamp), type);
}
