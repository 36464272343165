import styled, { css } from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import Div from "../../../toolkit/Div";
import Button from "../../../toolkit/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedNote,
  shareNoteAsync,
  stopNoteShareAsync,
} from "../../store";
import { useEffect, useRef, useState } from "react";
import { getShareDomain } from "../../../../shared-note-subdomain";

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.primary[1]};
  width: 30em;
  max-width: 80vw;
  padding: 1rem;
  border-radius: 5px;
  ${styleSystem()}
`;

const ShareLink = styled.div`
  height: 5em;
  word-break: break-all;
  overflow: hidden;
  border-radius: 3px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(p) => p.theme.colors.text};
  padding: 0 0.5em;
  background-color: ${(p) => p.theme.colors.primary[2]};
  border: 1px solid ${(p) => p.theme.colors.primary[0]};
`;

const CopyLink = styled.button`
  color: ${(p) => p.theme.colors.highlight};
  font-size: ${(p) => p.theme.fontSizes[1]};
  ${(p) =>
    p.copied
      ? css`
          color: ${(p) => p.theme.colors.text};
        `
      : ""};
`;

const ShareDialog = ({ ...props }) => {
  const note = useSelector(selectSelectedNote);
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState();
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef();

  async function createLink() {
    setUpdating(true);
    await dispatch(shareNoteAsync(note));
    setUpdating(false);
  }

  function nornaliseTitle(str) {
  str = str.toLowerCase().replaceAll(/[^a-z0-9]/ig, ' ');
  str = str.trim();
  str = str.replaceAll(' ', '-');
  str = str.replaceAll(/--+/g, '-');
  return str;
  }
  const hasNoteLink = !!note.shareId;
  const url = hasNoteLink
    ? `${getShareDomain()}/shared/${note.shareId}/${nornaliseTitle(note.title) }`
    : "";

  async function copyLink() {
    clearTimeout(timeoutRef.current);
    await navigator.clipboard.writeText(url);
    setCopied(true);
    timeoutRef.current = setTimeout(() => setCopied(false), 2000);
  }

  async function stopSharing() {
    setUpdating(true);

    await dispatch(stopNoteShareAsync(note));
    setUpdating(false);
  }

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);
  return (
    <Wrapper className="share-dialog" {...props}>
      <Div>
        <Div
          mb={10}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {hasNoteLink ? (
            <Button variant="warning" loading={updating} onClick={stopSharing}>
              Stop sharing
            </Button>
          ) : (
            <Button loading={updating} onClick={createLink}>
              Create Link
            </Button>
          )}
          {hasNoteLink && (
            <CopyLink copied={copied} onClick={copyLink}>
              {copied ? "Copied" : "Copy Link"}
            </CopyLink>
          )}
        </Div>
        <ShareLink>{url}</ShareLink>
      </Div>
    </Wrapper>
  );
};

export default ShareDialog;
