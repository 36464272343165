import styled from "styled-components";
import { styleSystem } from "../../toolkit/styleSystem";
import { useSelector } from "react-redux";
import { selectShowLoadingLine } from "../layoutSlice";

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;

  ${styleSystem()}
  .loading-indicator {
    &__line {
      height: 100%;
      width:0%;
      background-color: ${(p) => p.theme.colors.blue[5]};
      animation-name: anim;
      animation-duration: 1.7s;
      animation-iteration-count: infinite;
      animation-play-state: running;
      //animation-timing-function: cubic-bezier();
    }
  }

  @keyframes anim {
    from {
      width: 10%;
    }

    50% {
      width: 70%;
    }

    100% {
      margin-left:100%;
      width: 10%;
    }
  }
`;

const LoadingIndicator = ({ ...props }) => {
  const show = useSelector(selectShowLoadingLine);
  if(!show) return null;
  return (
    <Wrapper {...props}>
      <div className="loading-indicator__line"></div>
    </Wrapper>
  );
};

export default LoadingIndicator;
