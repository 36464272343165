import styled from "styled-components";
import { styleSystem } from "../../toolkit/styleSystem";

import { useCallback, useEffect, useRef, useState } from "react";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";
import debounce from "lodash-es/debounce";
import useSearch from "../../notes/useSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNoteAsync,
  selectSearchText,
} from "../../notes/store/notesSlice";
import { performSearchAsync } from "../../notes/store";
import useRouting, { routes } from "../../../hooks/useRouting";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 200;
  left: 10px;
  height: 95vh;
  width: 20rem;
  border-radius: 3px;
  background-color: ${(p) => p.theme.colors.primary[1]};
  ${styleSystem()}
`;
const Input = styled.input`
  outline: none;
  background-color: transparent;
  border: none;
  ${styleSystem()}
`;

const SearchSection = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 10px;
  height: 50px;
  margin-right: 5px;
  border-bottom: 1px solid ${(p) => p.theme.colors.primary[0]};
  ${styleSystem()}
`;

const ListSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 3px 10px;
  min-height: 3rem;
  margin-right: 5px;
  border-bottom: 1px solid ${(p) => p.theme.colors.primary[0]};
  ${styleSystem()}
`;
const Icon = styled(MagnifyingGlassIcon)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const ListItem = styled.div`
  padding: 0.4rem 0;
  align-items: center;
  display: flex;
  width: 100%;
  cursor: pointer;
`;

const SearchMenu = ({ close, ...props }) => {
  const inputRef = useRef();
  const wrapperRef = useRef();
  const { search, previousSearchTerms } = useSearch();
  const searchText = useSelector(selectSearchText);
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  useDetectOutsideClick(() => {
    close && close();
  }, wrapperRef);

  const dispatch = useDispatch();

  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.value = searchText;
  }, []);

  const changeHandler = useCallback(
    debounce((x) => {
      selectSearchTerm(x.target.value);
    }, 200),
    []
  );

  const selectItem = (note) => {
    dispatch(selectNoteAsync(note));
    close();
  };

  const selectSearchTerm = (term) => {
    if (inputRef?.current) {
      inputRef.current.value = term;

      setResults(search(term, 10));
    }
  };

  const onKeyPress = (evt) => {
    if (evt.code === "Enter") {
      doSearchAndClose(inputRef.current.value);
      evt.preventDefault();
    }
  };

  function doSearchAndClose(text) {
    navigate(routes.notes);
    dispatch(performSearchAsync(text));

    close && close();
  }
  return (
    <Wrapper
      {...props}
      ref={wrapperRef}
      onClick={() => {
        inputRef.current?.focus();
      }}
    >
      <SearchSection>
        <Icon />
        <Input
          width="100%"
          ref={inputRef}
          onKeyPress={onKeyPress}
          onChange={changeHandler}
        />
      </SearchSection>
      <ListSection>
        {previousSearchTerms.map((x) => (
          <ListItem
            key={x}
            onDoubleClick={() => {
              selectSearchTerm(x);
              doSearchAndClose(x);
            }}
            onClick={() => selectSearchTerm(x)}
          >
            {x}
          </ListItem>
        ))}
      </ListSection>
      {(results.length || null) && (
        <ListSection flexGrow={1}>
          {results.filter(Boolean).map((x) => (
            <ListItem
              key={x.id}
              onClick={() => selectItem(x)}
              style={{ cursor: "pointer" }}
            >
              {x.title}
            </ListItem>
          ))}
        </ListSection>
      )}
    </Wrapper>
  );
};

export default SearchMenu;
