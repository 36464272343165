import styled from "styled-components";
import { styleSystem } from "./styleSystem";
const StyledDiv = styled.div`
  ${p => p.gap ? `gap: ${p.gap};`: ``};
  ${styleSystem()}
`;

export default function Div({ ...props }) {
  return <StyledDiv {...props} />;
}
