import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import { useState } from "react";
import FullScreenPreview from "./FullScreenPreview";
import { downloadFile } from "../../../../utils/downloadFile";

import ActionBar from "./ActionBar";
import { iOS } from "../../../../utils/browser";
const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 288px;
  //border: 1px solid #545454;
  display: flex;
  flex-direction: column;
  padding: 0 0 0.5rem 0;
  justify-content: center;
  align-items: center;
  ${styleSystem()}
`;
const Title = styled.div`
  margin-top: 0.5rem;
  font-size: ${(p) => p.theme.fontSizes[0]};
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;
const AttachmentContainer = ({
  src,
  attachment,
  isEditable,
  doc,
  title,
  ...props
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const onClick = () => {
    setFullScreen(true);
  };
  return (
    <Wrapper className="attachment-container" height={['auto','auto','288px']} maxWidth={['100%','100%','300px']} {...props}>
      {!fullScreen && <DocWrapper height={['unset','unset','0px']}>{doc({ onClick })}</DocWrapper>}
      {fullScreen && (
        <FullScreenPreview
          onClose={() => setFullScreen(false)}
          filename={attachment.filename}
        >
          {doc}
        </FullScreenPreview>
      )}
      <Footer>
        <Title>{title}</Title>

        <ActionBar
          isEditable={isEditable}
          onDownload={() => downloadFile(src, attachment.meta.filename)}
          toggleFullscreen={() => iOS ? downloadFile(src, attachment.meta.filename) :  setFullScreen((x) => !x)}
          attachment={attachment}
        />
      </Footer>
    </Wrapper>
  );
};
const Footer = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 55px;
`;

const DocWrapper = styled.div`
  width: 100%;
height:0px;
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${styleSystem()}
`;

export default AttachmentContainer;
