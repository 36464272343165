import {
  activateLoadingLine,
  deactivateLoadingLine,
} from "../../../layout/layoutSlice";
import * as db from "../../db";
import {
  noteContentLoaded,
  notesFetched,
  selectNoteEntities,
  syncNotes,
  syncNotesComplete,
} from "../notesSlice";
import * as api from "../../github";
import { createLogger } from "../../../../utils/log";
import { initialiseSearchIndex } from "../../searchDb";

const log = createLogger("sync");
let isSyncing = false;

async function handleNote(dispatch, getState, gist) {
  const note = api.parseNoteGist(gist);
  const hasContent = await db.hasNoteContent(note);
  if (!hasContent) {
    const content = await api.getNoteContent(note);
    log.info("Updating note content", note.id);
    await db.setNoteContent(note, content.data);
    dispatch(noteContentLoaded(content));
  }
  await db.setNotes(note);
  dispatch(notesFetched([note]));
  return note;
}

export function syncNotesAsync({ fullSync, showSyncing, onSynced } = {}) {
  return async (dispatch, getState) => {
    if (isSyncing) return;
    isSyncing = true;
    // we can perform a full sync if its first sync or caller asks for full sync
    let performedFullSync = false;
    const gistIds = {};
    log.info("github syncing", { fullSync });
    try {
      showSyncing && dispatch(activateLoadingLine());
      const timestamp = fullSync
        ? undefined
        : await db.getLastUpdatedTimestamp();
      performedFullSync = !timestamp;
      let latestTimestamp = 0;
      dispatch(syncNotes());
      await api.getAllMyGists(timestamp, async (gists) => {
        for (const gist of gists) {
          latestTimestamp =
            gist.updated_at > latestTimestamp
              ? gist.updated_at
              : latestTimestamp;
          gistIds[gist.id] = true;

          switch (gist.type) {
            case api.gistTypes.note:
              await handleNote(dispatch, getState, gist);

              break;
            case api.gistTypes.sharedNote:
              break;
            default:
              log.error("unknown gist type", gist.type);
          }
        }
      });
      onSynced && (await onSynced(gistIds));
      latestTimestamp && (await db.setLastUpdatedTimestamp(latestTimestamp));
      await dispatch(syncNotesComplete());
    } finally {
      isSyncing = false;
      showSyncing && dispatch(deactivateLoadingLine());
    }
    log.info("github sync finished");
    return performedFullSync;
  };
}
