import styled from "styled-components";
import { styleSystem } from "../styleSystem";
import { HighlightOnHover } from "../HighlightOnHover";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";
import { useRef } from "react";

export const Popover = styled.div`
  position: relative;
  ${styleSystem()}
`;
const StyledPopoverMenu = styled.div`
  background-color: ${(p) => p.theme.colors.primary[1]};
  position: absolute;
  padding: 12px 0;
  z-index: 10;
  ${styleSystem()};
`;

export const PopoverMenu = ({ onClose, children, ...props }) => {
  const menuRef = useRef();
  useDetectOutsideClick(() => {
    onClose && onClose();
  }, menuRef);
  return (
    <StyledPopoverMenu className="popover-menu" {...props} ref={menuRef}>
      {children}
    </StyledPopoverMenu>
  );
};

export const StyledPopoverMenuItem = styled.div`
  height: 32px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  ${styleSystem()}
`;

export const PopoverMenuItem = ({ children, ...props }) => {
  return (
    <StyledPopoverMenuItem className="popover-menu-item" {...props}>
      <HighlightOnHover>{children}</HighlightOnHover>
    </StyledPopoverMenuItem>
  );
};

export const MenuItemDivider = styled.div`
  background-color: black;
  height: 1px;
  width: 100%;
  margin: 8px 0;
`;
