import styled from "styled-components";
import { Link } from "react-router-dom";
import Hamburger from "../features/toolkit/Hamburger";

const Wrapper = styled.div`
  padding: 2rem;

  a {
    color: white;
    font-size: 1.5rem;
  }
  li {
    margin-bottom: 2rem;
  }
`;

export default function TestPage() {
  return (
    <Wrapper>
      hamburger
      <Hamburger size={50} />
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/folder-tree">folder tree examples</Link>
        </li>
        <li>
          <Link to="/notes-list">notes-list</Link>
        </li>
        <li>
          <Link to="/note-editor">note-editor</Link>
        </li>
      </ul>
    </Wrapper>
  );
}
