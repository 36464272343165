import styled, { css } from "styled-components";
import timeSince from "../../../../utils/time-since";
import SharedIcon from "../SharedIcon";
import { getDescription, hasAttachments, isShared } from "../../getters";
import ListItemIcon from "../ListItemIcon";
import { PaperClipIcon } from "@heroicons/react/24/outline";

const Wrapper = styled.div`
  padding: 1em;
  line-height: 1.2;
  overflow-y: hidden;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid ${(p) => p.theme.colors.primary[1]};
  color: ${(p) => p.theme.colors.text};

  .note__ {
    &frame {
      position: absolute;
      top: 0;
      pointer-events: none;
      bottom: 0;
      left: 0;
      right: 0;
      ${(props) =>
        props.selected === "primary"
          ? css`
              border: 3px solid ${(p) => p.theme.colors.blue[4]};
            `
          : ""}
      ${(props) =>
        props.selected === "secondary"
          ? css`
              border: 3px solid ${(p) => p.theme.colors.gray[4]};
            `
          : ""}
    }

    &body {
      & * {
        font-size: ${(p) => p.theme.fontSizes[1]};
      }

      height: 55px;
      overflow: hidden;
    }

    &title {
      font-weight: ${(props) => props.theme.fontWeights.bold};
      font-size: ${(p) => p.theme.fontSizes[2]};
      margin-bottom: ${(p) => p.theme.space[3]};
    }

    &description {
      height: 2.5em;
      overflow: hidden;
    }

    &updated {
      margin-top: ${(props) => props.theme.space[5]};
      font-size: ${(props) => props.theme.fontSizes[0]};
    }
  }
`;
const NoteListItem = ({ note, selected, onClick, style }) => {
  return (
    <Wrapper
      selected={selected}
      style={style}
      onClick={() => onClick && onClick(note.id)}
    >
      <div className="note__frame" />
      <div className="note__body">
        <div className="note__title">
          {note.title}{" "}
          {isShared(note) && (
            <SharedIcon className={"h-5 pl-3 text-green-500 inline pb-[3px]"} />
          )}
          {hasAttachments(note) && (
            <PaperClipIcon
              className={"h-5 pl-1 pb-[3px] text-green-500 inline"}
            />
          )}
        </div>
        <div className="note__description">{getDescription(note)}</div>
      </div>
      <div className="note__updated">{timeSince(note.updated_at)}</div>
    </Wrapper>
  );
};

export default NoteListItem;
