import * as api from "../../github";
import * as db from "../../db";
import { noteUpdated } from "../notesSlice";

export function stopNoteShareAsync(note) {
  return async (dispatch) => {
    const updatedNote = await api.removeNoteShare(note);

    await db.setNotes(updatedNote);
    dispatch(noteUpdated(updatedNote));

  };
}