import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import { Attachment } from "./Attachment";

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  ${styleSystem()}
`;

const AttachmentList = ({ attachments, isEditable, ...props }) => {
  if (!attachments) return null;
  const sortedAttachments = attachments.sort(
    (a, b) => b.meta.timestamp - a.meta.timestamp
  );
  return (
    <Wrapper className="document-list" {...props}>
      {sortedAttachments.map((x) => (
        <Attachment
          isEditable={isEditable}
          key={x.meta.filename}
          attachment={x}
        ></Attachment>
      ))}
    </Wrapper>
  );
};

export default AttachmentList;
