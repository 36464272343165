import styled from "styled-components";
import {
  color,
  space,
  fontSize,
  compose,
  layout,
  variant,
} from "styled-system";

const StyledAlert = styled.div`
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0.9em 1em;
  ${compose(color, space, fontSize, layout)}
  ${variant({
    variants: {
      success: {
        backgroundColor: "alertSuccess.0",
        color: "alertSuccess.2",
        borderColor: "alertSuccess.1",
      },
      danger: {
        backgroundColor: "alertDanger.0",
        color: "alertDanger.2",
        borderColor: "alertDanger.1",
      },
    },
  })}
`;

export default function Alert({ children, ...props }) {
  return <StyledAlert {...props}>{children}</StyledAlert>;
}
