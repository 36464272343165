import styled from "styled-components";
import { Img } from "./Img";
import { Pdf } from "./Pdf";
import { Video } from "./Video";
import { Audio } from "./Audio";
import { DocumentTextIcon } from "@heroicons/react/24/solid";

export const AttachmentViewer = ({ type, ...props}) => {
  if (type.startsWith("image/")) return <Img {...props}></Img>;
  if (type === "application/pdf") return <Pdf {...props} />;
  if (type === "application/mp4") return <Video {...props} />;
  if (type.startsWith( "video/") || type.endsWith('/webm')) return <Video {...props} />;
  if (type.startsWith("audio/")) return <Audio {...props} />;
  return (
    <GenericDoc>
      <DocumentTextIcon style={{ width: "100%", height: "100%" }} />
    </GenericDoc>
  );
};

const GenericDoc = styled.div`
  width: 200px;
  height: 200px;
`;

export default Document;
