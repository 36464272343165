import styled from "styled-components";
import { styleSystem } from "../../toolkit/styleSystem";

const StyledCircleButton = styled.button`
  color: black;
  padding:0;
  position: relative;
  border: none;
  border-radius: 9999px;
  overflow:hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  width: 36px;
  height: 36px;
  ${styleSystem()}
`;

const CircleButton = ({  ...props }) => {
  return <StyledCircleButton {...props}></StyledCircleButton>;
};
export default CircleButton;
