import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectNoteAsync, selectNoteEntities } from "../../store/notesSlice";
import { styleSystem } from "../../../toolkit/styleSystem";
import { AutoSizer, List } from "react-virtualized";
import NoteListItem from "./NoteListItem";

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.primary[2]};
  overflow-y: hidden;

  .note-list {
    overflow-y: scroll !important;

    ::-webkit-scrollbar-track {
      background-color: ${(p) => p.theme.colors.primary[2]};
    }

    ::-webkit-scrollbar {
      width: ${(p) => p.theme.sizes.scrollbarTrack} !important;
      height: ${(p) => p.theme.sizes.scrollbarTrack} !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${(p) => p.theme.colors.primary[0]};
    }
  }
}

${styleSystem()}
`;

const NoteList = ({ noteIds, selectedNoteId, ...props }) => {
  const entities = useSelector(selectNoteEntities);

  const notes = noteIds.map((id) => entities[id]).filter(Boolean);
  const dispatch = useDispatch();

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    const x = notes[index];

    return (
      <NoteListItem
        style={style}
        key={key}
        note={x}
        selected={selectedNoteId === x.id ? "primary" : undefined}
        onClick={() => {
          dispatch(selectNoteAsync(x));
        }}
      />
    );
  }

  return (
    <Wrapper className="note-list" {...props}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              className="note-list scrollbar"
              width={width}
              height={height}
              rowCount={notes.length}
              rowHeight={110}
              rowRenderer={rowRenderer}
            />
          );
        }}
      </AutoSizer>
    </Wrapper>
  );
};

export default NoteList;
