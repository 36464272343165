import styled from "styled-components";
import { styleSystem } from "./styleSystem";

const Overlay = styled.div`
  position: absolute;
  background-color: black;
  opacity: 0.6;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${styleSystem()}
`;
export default Overlay;
