import { useLocation } from "react-router-dom";

export const routes = {
  settings: "/settings",
  notes: "/notes",
  deleted: "/deleted",
  test: "/test",
  shared: "/shared/:id/:desc?",
};
export default function useRouting() {
  const location = useLocation();
  const currentRoute = (Object.entries(routes).find(
    ([_, value]) => location.pathname === value
  ) || {})[0];

  return { currentRoute };
}
