import { useDispatch } from "react-redux";
import debounce from "lodash-es/debounce";
import { useEffect } from "react";

import { windowSizeChanged } from "./layoutSlice";
import isMobileView from "./isMobileView";
const WindowResizeTracker = () => {
  const dispatch = useDispatch();
  const notifyResize = (...args) => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const mobile = isMobileView();

    dispatch(windowSizeChanged({ width, height, isMobileView:mobile }));
  };
  // set on initial load
  notifyResize();
  useEffect(() => {
    const debouncedNotifyResize = debounce(notifyResize, 300);
    // Some devices not trigger resize on orientation change. listen to both events.
    window.addEventListener("resize", debouncedNotifyResize, false);
    window.addEventListener("orientationchange", debouncedNotifyResize, false);
    return () => {
      window.removeEventListener("resize", debouncedNotifyResize);
      window.removeEventListener("orientationchange", debouncedNotifyResize);
    };
  }, []);

  return null;
};
export default WindowResizeTracker;
