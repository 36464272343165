import { createGistDescriptionString } from "./common";
import { gistTypes } from "./noteApi";
import * as api from "./index";
import { createLogger } from "../../../utils/log";
import { CONTENT_FILENAME } from "../getters";

const log = createLogger("noteShareApi");

export async function removeNoteShare(note) {
  if (note.shareId) {
    await api.deleteGist(note.shareId);
    const updatedNote = await api.updateNote({ ...note, shareId: undefined });
    return updatedNote;
  }
  return note;
}

export async function updateNoteShare({ note, content }) {
  const description = createSharedNoteDescription({
    title: note.title,
    description: note.description,
  });

  const gist = await api.updateGist({
    id: note.shareId,
    description,
    files: { [CONTENT_FILENAME]: content },
  });
  return parseNoteShare(gist);
}

export async function createNoteShare({ note }) {
  const description = createSharedNoteDescription({
    title: note.title,
    description: note.description,
  });

  const files = await api.getAllGistFiles(note);
  const gist = await api.createGist({ description, files });
  const noteShare = parseNoteShare(gist);
  const updatedNote = await api.updateNote({ ...note, shareId: gist.id });
  return { updatedNote, noteShare };
}

function createSharedNoteDescription({ title, description }) {
  return createGistDescriptionString({
    type: gistTypes.sharedNote,
    title,
    description,
  });
}

function parseNoteShare(gist) {
  return createGistDescriptionString({
    ...gist,
    ...gist.description,
  });
}
