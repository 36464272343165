import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import React, { useEffect, useMemo } from "react";
import { useLogUnmount } from "../../../../hooks/useLogUnmount";
import { createLogger } from "../../../../utils/log";

const log = createLogger('previewContext');
export const PreviewContext = React.createContext(null);

const PreviewContextProvider = ({ editable, note, content, children }) => {
  const props = useMemo(
    () => ({ editable, note, content }),
    [editable, note, content]
  );

  useLogUnmount(log, 'preview context');
  return (
    <PreviewContext.Provider value={props}>
      {children}
    </PreviewContext.Provider>
  );
};

export default PreviewContextProvider;
