import { createSelector, createSlice } from "@reduxjs/toolkit";
import storage from "../../utils/storage";
import config from "./config";
export const name = "config";

export const configSlice = createSlice({
  name,
  initialState: {
    userInfo: config.getUserInfo(),
    pat: config.getPat(),
    tenant: storage.getTenant(),
    allTenants: storage.getAllTenants(),
  },
  reducers: {
    tenantsChanged: (state, action) => {
      state.allTenants = action.payload;
    },
  },
  extraReducers: {},
});

export const { tenantsChanged } = configSlice.actions;

export default configSlice.reducer;

export const selectConfig = (state) => state[name];

export const selectPat = createSelector(selectConfig, (state) => state.pat);
export const selectUserInfo = createSelector(
  selectConfig,
  (state) => state.userInfo
);

export const selectAllTenants = createSelector(
  selectConfig,
  (state) => state.allTenants
);

export const selectTenant = createSelector(
  selectConfig,
  (state) => state.tenant
);
