import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../config";
import { styleSystem } from "../../toolkit/styleSystem";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  ${styleSystem()}
`;

const Avatar = ({ ...props }) => {
  const config = useSelector(selectUserInfo);

  return (
    <Wrapper {...props}>
      <img alt="avatar" src={config.avatar_url} />
    </Wrapper>
  );
};
export default Avatar;
