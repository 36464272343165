import {
  color,
  space,
  layout,
  compose,
  background,
  border,
  position,
  flexbox,
  typography,
  grid,
  boxShadow,
  textShadow,
} from "styled-system";
export function styleSystem() {
  return compose(
    color,
    space,
    layout,
    background,
    border,
    position,
    flexbox,
    typography,
    grid,
    boxShadow,
    textShadow
  );
}
