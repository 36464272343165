import storage from "../../utils/storage";

const SCHEMA_KEY = "SCHEMA_VERSION";
const name = "config";
const PAT_STORAGE_KEY = `${name}:pat`;
const USERINFO_STORAGE_KEY = `${name}:userinfo`;

const config = {
  getSchemaVersion() {
    return storage.getItem(SCHEMA_KEY) || 1;
  },
  incrementSchemaVersion() {
    const version = this.getSchemaVersion();
    storage.setItem(SCHEMA_KEY, version + 1);
  },
  getTenant: storage.getTenant,
  changeTenant(tenant) {
    storage.setTenantOnReload(tenant);
    window.location = window.location.origin;
  },
  setLoginDetails(userInfo, pat) {
    const tenant = userInfo.login;
    storage.setTenantOnReload(tenant);
    storage.setItem(PAT_STORAGE_KEY, pat, tenant);
    storage.setItem(USERINFO_STORAGE_KEY, userInfo, tenant);
  },
  getUserInfo() {
    return storage.getItem(USERINFO_STORAGE_KEY);
  },
  getPat() {
    return storage.getItem(PAT_STORAGE_KEY);
  },
};

export default config;
