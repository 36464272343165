const TENANT_KEY = "tenant";
const TENANT_LIST_KEY = "tenant-list";
// we never update the active tenant of a running app.
// The only way to set is to update in local storage then make user reload
// to avoid at corruption.
const tenant = _getTenant();
function setTenantOnReload(login) {
  _setItem(TENANT_KEY, login);
  addTenant(login);
}

function addTenant(login) {
  const list = _getItem(TENANT_LIST_KEY) || [];
  if(!list.includes(login)) {
    list.push(login);
    _setItem(TENANT_LIST_KEY, list);
  }
}

function getAllTenants() {
  return _getItem(TENANT_LIST_KEY) || [];
}

function getTenant() {
  return tenant;
}

function setItem(key, value, tenantOverride = '') {
  _setItem(`${tenantOverride || tenant}|${key}`, value);
}
function getItem(key) {
  return _getItem(`${tenant}|${key}`);
}

function _getTenant() {
  return  _getItem(TENANT_KEY) || '';
}
function _setItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function _getItem(key) {
  key = `${key}`;
  const value = localStorage.getItem(key);
  if (value == null) return null;
  try {
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
}
const storage = { getTenant, setTenantOnReload, setItem, getItem, addTenant, getAllTenants };
export default storage;
