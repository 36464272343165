import styled from "styled-components";
import { styleSystem } from "../../../toolkit/styleSystem";
import { useDispatch } from "react-redux";
import { deleteAttachmentAsync } from "../../store";
import Button from "../../../toolkit/Button";

import { useState } from "react";
import {
  Popover,
  PopoverMenuItem,
  PopoverMenu,
  MenuItemDivider,
} from "../../../toolkit";
import Modal from "../../../toolkit/Modal";
import Input from "../../../toolkit/Input";
import Div from "../../../toolkit/Div";
import { renameAttachmentAsync } from "../../store/actions/renameAttachmentAsync";
import {
  ArrowsPointingOutIcon,
  CloudArrowDownIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";

const StyledActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  ${styleSystem()}
`;
const ActionBar = ({
  onDownload,
  isEditable,
  attachment,
  toggleFullscreen,
  ...props
}) => {
  const dispatch = useDispatch();

  const deleteAttachment = () => {
    setShowMore(false);
    dispatch(deleteAttachmentAsync(attachment));
  };
  const [showMore, setShowMore] = useState();
  const [showRename, setShowRename] = useState(false);

  const onRename = async (attachment, newFilename) => {
    dispatch(renameAttachmentAsync(attachment, newFilename));
    setShowRename(false);
  };
  return (
    <>
      <StyledActionBar className="action-bar" {...props}>
        <Button variant="transparent" onClick={onDownload}>
          <CloudArrowDownIcon className={"h-6"} />
        </Button>
        <Button onClick={toggleFullscreen} variant="transparent">
          <ArrowsPointingOutIcon className={"h-6"} />
        </Button>
        {isEditable && (
          <Popover>
            <Button
              variant="transparent"
              onClick={() => setShowMore((x) => !x)}
            >
              <EllipsisHorizontalIcon className={"h-6"} />
            </Button>
            {showMore && (
              <PopoverMenu onClose={() => setShowMore(false)}>
                <PopoverMenuItem>
                  <Button
                    px={10}
                    onClick={() => setShowRename(true)}
                    variant="invisible"
                  >
                    Rename
                  </Button>
                </PopoverMenuItem>
                <MenuItemDivider />
                <PopoverMenuItem>
                  <Button
                    px={10}
                    onClick={deleteAttachment}
                    variant="invisible"
                  >
                    Delete
                  </Button>
                </PopoverMenuItem>
              </PopoverMenu>
            )}
          </Popover>
        )}
      </StyledActionBar>
      {showRename && (
        <Modal onBackgroundClick={() => setShowRename(false)}>
          <RenameDialog
            attachment={attachment}
            onRename={onRename}
          ></RenameDialog>
        </Modal>
      )}
    </>
  );
};

const StyledRenameDialog = styled.div`
  z-index: 40;
  background-color: ${(p) => p.theme.colors.primary[0]};
  ${styleSystem()}
  width:30rem;
  padding: 2rem;
  max-width: 100%;
`;

const RenameDialog = ({ attachment, onRename, ...props }) => {
  const [value, setValue] = useState(attachment.meta.filename);
  return (
    <StyledRenameDialog {...props}>
      Rename File
      <Input
        fontSize={2}
        inputProps={{ value, onChange: (e) => setValue(e.target.value) }}
      ></Input>
      <Div style={{ float: "right", marginTop: "1rem" }}>
        <Button onClick={() => onRename && onRename(attachment, value)}>
          Continue
        </Button>
      </Div>
    </StyledRenameDialog>
  );
};

export default ActionBar;
