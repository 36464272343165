import styled from "styled-components";
import Hamburger from "../../toolkit/Hamburger";
import Button from "../../toolkit/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addNoteAsync,
  selectIsEditMode,
  selectSelectedNote,
  setEditingMode,
  openSearch,
} from "../../notes/store/notesSlice";
import { selectIsMobileView, toggleExpandSideNav } from "../layoutSlice";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../hooks/useRouting";
import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";

const Wrapper = styled.div`
  position: fixed;
  height: ${(p) => p.theme.sizes.footerHeight};
  display: flex;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: ${(p) => p.theme.colors.primary[1]};

  .mobile-footer__top-row {
    width: 100%;
    display: flex;
    height: 50px;
  }

  .mobile-footer__section {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;

    :nth-child(2) {
      justify-content: center;
    }

    :nth-child(3) {
      justify-content: flex-end;
    }
  }
`;

const MobileFooter = () => {
  const dispatch = useDispatch();
  const mobile = useSelector(selectIsMobileView);
  const navigate = useNavigate();
  const isEditMode = useSelector(selectIsEditMode);
  const note = useSelector(selectSelectedNote);
  const addNew = () => {
    navigate(routes.notes);
    dispatch(addNoteAsync());
  };

  const toggleExpand = () => {
    dispatch(toggleExpandSideNav());
  };

  if (!mobile || isEditMode) {
    return null;
  }

  function onOpenSearch() {
    navigate(routes.notes);
    dispatch(openSearch());
  }
  return (
    <Wrapper>
      <div className="mobile-footer__top-row">
        <div className="mobile-footer__section">
          <Hamburger
            onClick={toggleExpand}
            size={30}
            color="white"
            ml={10}
            mt={7}
          />
        </div>
        <div className="mobile-footer__section">
          {note ? (
            <Button
              onClick={() => dispatch(setEditingMode(!isEditMode))}
              borderRadius="20px"
              p={10}
              postion="relative"
              width="6rem"
              variant="edit"
              top={-15}
            >
              Edit
            </Button>
          ) : (
            <Button
              onClick={addNew}
              borderRadius="20px"
              p={10}
              width="6rem"
              postion="relative"
              top={-15}
            >
              <PlusIcon /> New
            </Button>
          )}
        </div>
        <div className="mobile-footer__section">
          <Button
            variant="transparent"
            height="30px"
            color="white"
            onClick={onOpenSearch}
            mr={10}
            mt={7}
          >
            <MagnifyingGlassIcon style={{ width: "28px", height: "28px" }} />
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default MobileFooter;
