import styled from "styled-components";
import { styleSystem } from "./styleSystem";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const rootElement = document.body;
const Wrapper = styled.div`
  ${styleSystem()}
`;

const Portal = ({ children, ...props }) => {
  const [ele] = useState(() => document.createElement("div"));
  useEffect(() => {
    rootElement.appendChild(ele);
    return () => {
      rootElement.removeChild(ele);
    };
  }, [ele]);
  if (!ele) return null;
  return ReactDOM.createPortal(<Wrapper {...props}>{children}</Wrapper>, ele);
};

export default Portal;
