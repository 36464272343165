import styled from "styled-components";
import { styleSystem } from "../toolkit/styleSystem";
import theme from "../../theme";

const lineWidth = "10%";
const Wrapper = styled.button`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  outline:none;
  background-color: transparent;
  border:none;
  display: flex;
  flex-direction: column;
  padding:5px;
  position: relative;
  justify-content: space-around;

  :before {
    content: '';

    display: block;
    width: 100%;
    height:${lineWidth};
    border-radius: 5px;
    background-color: ${(p) => p.color};
  }

  :after {
    content: '';
    display: block;
    width: 100%;
    border-radius: 5px;
    height:${lineWidth};
    background-color: ${(p) => p.color};
  }

  .hamburger__line {
    width: 100%;
    border-radius: 5px;
    background-color: ${(p) => p.color};
    height:${lineWidth};
  }
  .hamburger__overlay {
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    opacity: 0;
  
    color:white;
    background-color: white;
    z-index: 10;
    @media (pointer: fine) and (hover) {
      :hover {
        opacity: 0.1;
      }
    }
    :active {
      
    }
  }
}

${styleSystem()}
`;

const Hamburger = ({ size, color, ...props }) => {
  return (
    <Wrapper size={size} color={color || theme.colors.secondary[0]} {...props}>
      <div className="hamburger__line" />
      <div className="hamburger__overlay" />
    </Wrapper>
  );
};

export default Hamburger;
