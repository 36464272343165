import styled from "styled-components";
import { styleSystem } from "../../features/toolkit/styleSystem";
import { useEffect, useState } from "react";
import * as db from "../../features/notes/db";
import { getDeletedNotes } from "../../features/notes/db";
import Button from "../../features/toolkit/Button";
import { useDispatch } from "react-redux";
import {
  hardDeleteNoteAsync,
  restoreNoteAsync,
} from "../../features/notes/store";

const StyledDeletedNotes = styled.div`
  overflow-y: auto;
  height: 100%;
  background-color: ${(p) => p.theme.colors.primary[2]};
  .deleted-notes {
    &__buttons {
      display: flex;
      margin-top: 1rem;
      gap: 2rem;
      //justify-content: space-around;
    }
    &__last-updated {
      font-size: ${(p) => p.theme.fontSizes[0]};
    }
    &__list-item {
      padding: 1rem 0.5rem;
      border-bottom: 1px solid ${(p) => p.theme.colors.primary[1]};
    }
    &__title {
      font-weight: ${(p) => p.theme.fontWeights.semibold};
    }
    &__description {
      margin-top: 0.5rem;
    }
  }
  ${styleSystem()}
`;

const DeletedNotes = ({ ...props }) => {
  const [notes, setNotes] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const notes = await db.getDeletedNotes();
      setNotes(notes.sort((a, b) => b.updated_at - a.updated_at));
    })();
  }, []);
  async function deleteNote(note) {
    setNotes((x) => x.filter((y) => y.id !== note.id));
    await dispatch(hardDeleteNoteAsync(note));
  }
  async function restoreNote(note) {
    setNotes((x) => x.filter((y) => y.id !== note.id));
    await dispatch(restoreNoteAsync(note));
  }
  return (
    <StyledDeletedNotes className="scrollbar" {...props}>
      {notes.map((x) => (
        <div key={x.id} className="deleted-notes__list-item">
          <div>
            <div className="deleted-notes__title">{x.title}</div>
            <div className="deleted-notes__last-updated">
              Last Updated {new Date(x.updated_at).toDateString()}
            </div>
            <div className="deleted-notes__description">{x.description}</div>
          </div>
          <div className="deleted-notes__buttons">
            <Button onClick={() => deleteNote(x)} variant="warning">
              Delete
            </Button>

            <Button onClick={() => restoreNote(x)}>Restore</Button>
          </div>
        </div>
      ))}
    </StyledDeletedNotes>
  );
};

export default DeletedNotes;
