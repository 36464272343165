import { isSafari } from "../../../../utils/browser";
import styled from "styled-components";

export const Video = ({ autoPlay, loop, ...props }) => {
  return (
    <>
      {isSafari && autoPlay && loop ? (
        <StyledImg {...props} />
      ) : (
        <StyledVideo {...props} autoPlay={autoPlay} loop={loop} controls muted>
          Unsupported
        </StyledVideo>
      )}
    </>
  );
};
const StyledImg = styled.img`
  max-width: 100%;
  width: fit-content;
  height: auto;
  max-height: 100%;
  display: block;
  ${p => p.fullscreen ? `width:unset; height:unset; max-width: unset; max-height:unset;`: ''}
`;
const StyledVideo = styled.video`
  max-width: 100%;
  display: block;
  width: fit-content;
  max-height: 100%;
  ${p => p.fullscreen ? `width:unset; height:unset; max-width: unset; max-height:unset;`: ''}
`;
